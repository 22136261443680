//messages.js
export default {
   general: 'Генеральная',
   overview: 'обзор',
   customizer: 'Настройщик',
   features: 'Features',
   components: 'Компоненты',
   applications: 'Приложения',
   salesAndVisitStats: 'Статистика продаж и посещений',
   orderAndProjectStats: 'Статистика заказов и проектов',
   fitnessStats: 'Фитнес-статистика',
   supportAndUsefulWidgets: 'Поддержка и полезные виджеты',
   dashboard: 'Панель приборов',
   dashboardOverview: 'Обзор панели мониторинга',
   dashboardv1: 'Панель управления V1',
   dashboardv2: 'Панель управления V2',
   widgets: 'Виджеты',
   horizontalMenu: 'Горизонтальное меню',
   pages: 'страницы',
   gallery: 'Галерея',
   pricing: 'ценообразование',
   pricing1: 'ценообразование 1',
   pricing2: 'ценообразование 2',
   blank: 'пустой',
   session: 'сессия',
   signUp1: 'Зарегистрироваться 1',
   signUp2: 'Зарегистрироваться 2',
   login1: 'Вход 1',
   login2: 'Вход 2',
   lockScreen: 'Экран блокировки',
   uiElements: 'Элементы пользовательского интерфейса',
   buttons: 'Кнопки',
   cards: 'Карты',
   checkbox: 'флажок',
   carousel: 'Карусель',
   chips: 'чипсы',
   datepicker: 'Datepicker',
   dialog: 'диалог',
   grid: 'сетка',
   input: 'вход',
   list: 'Список',
   menu: 'Меню',
   progress: 'Прогресс',
   radio: 'Радио',
   select: 'Выбрать',
   slider: 'ползунок',
   snackbar: 'Закусочная',
   tabs: 'Вкладки',
   toolbar: 'Панель инструментов',
   tooltip: 'подсказка',
   timepicker: 'Timepicker',
   forms: 'формы',
   formValidation: 'Проверка формы',
   stepper: 'Шаговый',
   charts: 'Графики',
   vueChartjs: 'Vue Chartjs',
   vueEcharts: 'Vue Echarts',
   icons: 'Иконки',
   themify: 'Themify',
   material: 'материал',
   tables: 'таблицы',
   standard: 'стандарт',
   slots: 'игровые автоматы',
   selectable: 'Выбор',
   searchRow: 'Поиск строки',
   maps: 'Карты',
   googleMaps: 'Карты Гугл',
   leafletMaps: 'Листовые карты',
   jvectorMap: 'Карта Jvector',
   inbox: 'входящие',
   users: 'пользователей',
   userProfile: 'Профиль пользователя',
   usersList: 'Список пользователей',
   calendar: 'Календарь',
   editor: 'редактор',
   quillEditor: 'Редактор Quill',
   wYSIWYG: 'WYSIWYG',
   vue2Dragula: 'Vue2 Dragula',
   dragAndDrop: 'Перетаскивание',
   vueDraggable: 'Vue Draggable',
   draggableResizeable: 'Draggable Resizable',
   chat: 'чат',
   todo: 'Делать',
   modules: 'Модули',
   user: 'пользователь',
   miscellaneous: 'Разное',
   promo: 'рекламный',
   connections: 'связи',
   follow: 'следить',
   unfollow: 'Отписаться',
   basicValidation: 'Базовая проверка',
   validationWithSubmitAndclear: 'Проверка с помощью submit & clear',
   submit: 'Отправить',
   clear: 'Очистить',
   step1: 'Шаг 1',
   step2: 'Шаг 2',
   step3: 'Шаг 3',
   continue: 'Продолжать',
   cancel: 'Отмена',
   totalEarnings: 'Всего заработка',
   onlineRevenue: 'Выручка в Интернете',
   offlineRevenue: 'Не в сети',
   marketingExpenses: 'Маркетинговые расходы',
   newCustomers: 'новые клиенты',
   visitAndSalesStatistics: 'Статистика посещений и продаж',
   collapse: 'коллапс',
   reload: 'перезагружать',
   close: 'Закрыть',
   bandwidthUsage: 'Использование полосы пропускания',
   shoppingCart: 'Корзина',
   totalDownloads: 'Всего загрузок',
   productSales: 'Продажи продуктов',
   projectStatus: 'Статус проекта',
   averageSteps: 'Средние шаги',
   todaysDistance: "Сегодняшнее расстояние",
   todaysStep: "Сегодняшний шаг",
   todaysGoal: "Сегодняшняя цель",
   calories: 'Калории',
   weeklySummary: 'Еженедельный обзор',
   supportTickets: 'Билеты на поддержку',
   todoList: 'Список дел',
   newsletterCampaign: 'Информационная кампания',
   currentVisitors: 'Текущие посетители',
   newMembers: 'Новые участники',
   addNewBlog: 'Добавить новый блог',
   add: 'Добавить',
   logOut: 'Выйти',
   totalAppMemory: 'Общая память приложения',
   totalMemoryUsed: 'Используемая общая память',
   unreadMail: '12 Непрочитанная почта',
   feedback: 'Обратная связь',
   flatButton: 'Плоская кнопка',
   raisedButton: 'Поднятая кнопка',
   gradientButton: 'Кнопка Gradient',
   buttonDropdownVariants: 'Варианты раскрывающегося списка кнопок',
   buttonToggle: 'Кнопка переключения',
   icon: 'Значок',
   normal: 'Нормальный',
   disabled: 'Отключено',
   floating: 'плавучий',
   loaders: 'Погрузчики',
   sizing: 'Определение размеров',
   outline: 'Контур',
   round: 'Круглый',
   block: 'блок',
   search: 'Поиск',
   checkboxesBoolean: 'Флажки Boolean',
   checkboxesArray: 'Флажок',
   checkboxesStates: 'Флажки - Штаты',
   checkboxesColors: 'Флажки - Цвета',
   switches: 'Выключатели',
   default: 'По умолчанию',
   customTransition: 'Пользовательский переход',
   customDelimiter: 'Пользовательский разделитель',
   hideControls: 'Скрыть элементы управления',
   hideDelimiters: 'Скрыть разделители',
   colored: 'цветной',
   label: 'метка',
   closeable: 'Closeable',
   datePickerHorizontal: 'Выбор даты по горизонтали',
   datePickerVertical: 'Выбор даты по вертикали',
   datePickersInDialogAndMenu: 'Выбор даты - в диалоге и меню',
   datePickersWithModal: 'Выбор даты с модальными',
   datePickersFormattingDate: 'Выбор даты - дата форматирования',
   simpleDialogs: 'Простые диалоги',
   withoutActivator: 'Без активатора',
   scrollable: 'Scrollable',
   listOfAllMaterialIcons: 'Список всех материалов Icons',
   arrowsAndDirectionsIcons: 'СТРЕЛКИ И НАПРАВЛЕНИЯ ИКОНЫ',
   webAppIcons: 'ВЕБ-ПРИЛОЖЕНИЯ',
   CONTROLICONS: 'КОНТРОЛЬНЫЕ ИКОНЫ',
   TEXTEDITOR: 'ТЕКСТОВЫЙ РЕДАКТОР',
   LAYOUTICONS: 'ЛАЙТНЫЕ ИКОНЫ',
   BRANDICONS: 'БРЕНД-ИКОНЫ',
   newClients: 'Новые клиенты',
   recurringClients: 'Повторяющиеся клиенты',
   bounceRate: 'Показатель отказов',
   pageViews: 'Просмотров страницы',
   usefulWidgets: 'Полезные виджеты',
   sale: 'Продажа',
   sales: 'Продажи',
   invoices: 'Счета-фактуры',
   referrals: 'Переходов',
   serverLoad: 'Загрузка сервера',
   recentSale: 'Недавняя продажа',
   supportRequest: 'Запрос поддержки',
   productSale: 'Продажа продукции',
   viewAll: 'ПОСМОТРЕТЬ ВСЕ',
   barChart: 'Барная диаграмма',
   polarAreaChart: 'Полярная площадь',
   lineChart: 'Линейный график',
   radarChart: 'Радарная диаграмма',
   doughnutChart: 'Диаграмма пончика',
   bubbleChart: 'Диаграмма пузырьков',
   gradientLineChart: 'Градиентная линейная диаграмма',
   pieChart: 'Круговая диаграмма',
   funnelChart: 'График воронки',
   loginNow: 'Вход в систему',
   createAccount: 'Регистрация',
   termsOfService: 'Условия использования',
   bySigningUpYouAgreeTo: 'Зарегистрировавшись, вы соглашаетесь',
   loginToAdmin: 'Вход для пользователей',
   enterUsernameAndPasswordToAccessControlPanelOf: 'Введите имя пользователя и пароль для доступа к панели управления',
   havingAnAccount: 'У вас есть учетная запись?',
   quickLinks: 'Быстрые ссылки',
   plans: 'планы',
   chooseThePlanThatWorksForYou: 'Выберите план, который будет работать для вас.',
   monthly: 'ежемесячно',
   yearlyGet2MonthExtra: 'Ежегодно (получите 2 месяца)',
   basic: 'основной',
   pro: 'профессионал',
   advanced: 'продвинутый',
   basicFree: 'Основные бесплатные',
   startToBasic: 'Начать к основным',
   upgradeToPro: 'Обновление до Pro.',
   upgradeToAdvance: 'Обновление до продвинутого',
   comparePlans: 'Сравнить планы',
   frequentlyAskedQuestions: 'Часто задаваемые вопросы',
   defaultInput: 'Ввод по умолчанию',
   activator: 'возбудитель',
   hover: 'зависать',
   menus: 'меню',
   indeterminate: 'неопределенный',
   sizeAndWidth: 'Размер и ширина',
   rotate: 'Поворот',
   determinate: 'детерминированный',
   buffer: 'буфер',
   radiosDefault: 'Радиостанции по умолчанию',
   radiosDirectionRow: 'Строка направления радиоприемников',
   radiosDirectionColumn: 'Колонка направления радиостанций',
   radiosColors: 'Радио - Цвета',
   switchesColors: 'Переключатели - Цвета',
   continuous: 'непрерывный',
   discrete: 'дискретный',
   customColors: 'Пользовательские цвета',
   editableNumericValue: 'Редактируемое числовое значение',
   contextualSnackbar: 'Контекстная закусочная',
   showSnackbar: 'Показать Снекбар',
   centeredTabs: 'Центрированные вкладки',
   toolbarTabs: 'Вкладки панели инструментов',
   theDefaultColorToolbar: 'Панель инструментов по умолчанию',
   appBar: 'Панель приложений',
   appBarWithExtension: 'Панель приложений с расширением',
   visibility: 'видимость',
   top: 'верхний',
   right: 'Правильно',
   bottom: 'Дно',
   left: 'Оставил',
   toggle: 'тумблер',
   timePicker: 'Выбор времени',
   timePickerInDialogAndMenu: 'Выбор времени - в диалоге и меню',
   itemsAndHeaders: 'Предметы и заголовки',
   selectableRows: 'Выбираемые строки',
   headerCell: 'Заголовок заголовка',
   expand: 'расширять',
   recentChat: 'Последние сообщения',
   previousChats: 'Предыдущие чаты',
   addNewItems: 'Добавить новые элементы',
   addToDo: 'Добавить',
   yes: 'да',
   ok: 'ОК',
   activeUsers: 'Активные пользователи',
   addNew: 'Добавить новое',
   readMore: 'Прочитайте больше',
   assignNow: 'Назначить сейчас',
   totalRequest: 'Общий запрос',
   new: 'новый',
   pending: 'в ожидании',
   update: 'Обновить',
   updated10MinAgo: 'Обновлено 10 мин назад',
   addNewCustomer: 'Добавить нового клиента',
   name: 'имя',
   email: 'Эл. адрес',
   editMember: 'Редактировать участника',
   scheduleDate: 'Дата расписания',
   title: 'заглавие',
   newEmails: 'Новые электронные письма',
   newEmail: 'Новый E-mail',
   employeePayroll: 'Заработная плата сотрудников',
   forMostOfTheUsers: 'Для большинства пользователей',
   choosePlan: 'Выберите План',
   mega: 'мега',
   master: 'Мастер',
   forDeveloper: 'для разработчиков',
   forLargeEnterprises: 'Для крупных предприятий',
   composeEmail: 'Написать письмо',
   mailboxes: 'Почтовые ящики',
   folders: 'Папки',
   settings: 'настройки',
   inbox5: 'Входящие (5)',
   draft: 'Проект',
   starred: 'Помеченные',
   sentMessages: 'Отправленные сообщения',
   spam: 'Спам',
   delete: 'Удалить',
   work: 'Работа',
   personal: 'личный',
   manageFolders: 'Управление папками',
   contacts: 'контакты',
   themeOptions: 'Параметры темы',
   darkMode: 'Темный режим',
   collapseSidebar: 'Свернуть боковую панель',
   boxLayout: 'Макет коробки',
   rtlLayout: 'Rtl Layout',
   backgroundImage: 'Изображение на заднем плане',
   sidebarFilters: 'Боковые панели',
   danger: 'розовый',
   primary: 'первичный',
   warning: 'первичный',
   success: 'успех',
   info: 'Пурпурный',
   dailySales: 'Ежедневные продажи',
   trafficChannel: 'Канал трафика',
   campaignPerformance: 'Эффективность кампании',
   goToCampaign: 'Перейти в кампанию',
   fullScreen: 'Полный экран',
   ecommerce: 'Электронная торговля',
   shop: 'Магазин',
   cart: 'телега',
   viewCart: 'Просмотр корзины',
   total: 'Bсего',
   totalPrice: 'Итоговая цена',
   checkout: 'контроль',
   apply: 'Подать заявление',
   noItemsFound: 'Ничего не найдено',
   billingDetails: 'Платежные реквизиты',
   placeOrder: 'Разместить заказ',
   addNewCard: 'обавить новую карту',
   devicesShare: 'Устройства Поделиться',
   customerOverview: 'Обзор клиента',
   reviews: 'Отзывы',
   weeklySales: 'Еженедельные продажи',
   recentOrders: 'Недавние заказы',
   categorySales: 'Категория Продажа',
   topSelling: 'Лучшие продажи',
   social: 'Социальное',
   newPost: 'Новый пост',
   publish: 'опубликовать',
   webAnalytics: 'Веб-аналитика',
   magazine: 'журнал',
   editUser: 'Редактировать пользователя',
   addNewUser: 'Добавить пользователя',
   dark: 'Темно',
   light: 'Легкий',
   recentUsers: 'Последние пользователи',
   activityAroundWorld: 'Деятельность во всем мире',
   adsIncome: 'Доход от рекламы',
   recentComments: 'недавние комментарии',
   recentNotifications: 'Последние уведомления',
   messages: 'Сообщения',
   edit: 'редактировать',
   topAuthors: 'Лучшие авторы',
   marketingCampaign: 'Маркетинговая кампания',
   articles: 'статьи',
   websiteTraffic: 'Движение сайта',
   agency: 'Агентство',
   recent: 'последний',
   trending: 'Trending',
   totalPageViews: 'Всего просмотров страниц',
   impressions: 'Впечатления',
   deviceSeparations: 'Разделение устройств',
   browserStatics: 'Статистика браузера',
   totalDownloading: 'Всего загрузок',
   notifications: 'Уведомления',
   totalBookmarked: 'Всего закладок',
   itemsDownloads: 'Загрузка элементов',
   itemsUploaded: 'Загруженные предметы',
   totalAnalytics: 'Общая аналитика',
   hotKeywords: 'Горячие ключевые слова',
   MaleUsers: 'Мужчины-пользователи',
   serverStatus: 'Состояние сервера',
   purchaseVuely: 'Покупка Vuely',
   letsGetInTouch: 'Позволяет войти в контакт',
   maleUsers: 'Мужчины-пользователи',
   femaleUsers: 'Женские пользователи',
   androidUsers: 'משתמשי Android',
   iOSUsers: 'משתמשי iOS',
   advance: 'авансировать',
   routerAnimation: 'Анимация маршрутизатора',
   salesAndEarning: 'Продажи и заработок',
   netProfit: 'Чистая прибыль',
   totalExpences: 'Суммарные расходы',
   photos: 'Фото',
   adCampaignPerfomance: 'Эффективность рекламных кампаний',
   profitShare: 'Доля прибыли',
   topSellingTheme: 'Главная тема продажи',
   newRequest: 'Новый запрос',
   followers: 'Читают',
   mailbox: 'почтовый ящик',
   sent: 'Отправлено',
   birthdays: 'Дни рождения',
   events: 'Мероприятияc',
   newsletter: 'Новостная рассылка',
   lastMonth: 'Прошлый месяц',
   allTime: 'Все время',
   composeNewEmail: 'Создать новый адрес электронной почты',
   activity: 'Мероприятия',
   message: 'Сообщение',
   selectTheme: 'Выберите тему',
   continueShopping: 'Продолжить покупки',
   proceedToCheckout: 'Перейти к оформлению заказа',
   headerFilters: 'Фильтры заголовка',
   blog: "Блог",
   signUp: "Зарегистрироваться",
   login: "Авторизоваться",
   news: "новости",
   topHeadlines: "Лучшие заголовки",
   visitors: "посетители",
   subscribers: "Подписчики",
   twitterFeeds: "Твиттер",
   extensions: "расширения",
   imageCropper: "Изображение Cropper",
   videoPlayer: "Видео проигрыватель",
   dropzone: "Зона сброса",
   baseConfig: "Базовая конфигурация",
   audioTrackAndPlaysInline: "Аудиодорожка и воспроизведение Inline",
   hlsLive: "HLS Live",
   forgotPassword: "Забыли пароль",
   resetPassword: "Сброс пароля",
   backToSignIn: "Назад для входа",
   enterYourEmailToSendYouAResetLink: "Введите свой адрес электронной почты, чтобы отправить ссылку на сброс",
   pleaseEnterYourPasswordToReset: "Введите пароль для сброса",
   saas: "SAAS",
   overallTrafficStatus: "Общее состояние трафика",
   projectManagement: "Управление проектом",
   projectTaskManagement: "Управление проектами",
   totalSales: "Тотальная распродажа",
   simple: "просто",
   transitions: "Переходы",
   tax: "налог",
   expenses: "Затраты",
   images: "Изображений",
   ratings: "Рейтинги",
   containAndCover: 'Содержит и обложку',
   height: 'Высота',
   fixedRatio: 'Фиксированный коэффициент',
   gradients: 'Градиенты',
   sizeVariants: 'Варианты размеров',
   newIcons: 'Новые иконки',
   webApplication: 'Веб приложение',
   person: 'Человек',
   file: 'файл',
   comment: 'Комментарий',
   form: 'форма',
   hardware: 'аппаратные средства',
   directional: 'направленный',
   mapAliases: 'Карта (Псевдонимы)',
   dateAndTime: 'Дата и время',
   groups: "группы",
   buttonGroups: "Группы кнопок",
   itemGroups: "Группы элементов",
   windows: "Windows",
   onBoarding: "Посадка",
   accountCreation: "Создание аккаунта",
   treeview: "В виде дерева",
   customSelectableIcons: "Пользовательские выбираемые значки",
   timelines: "Хронологические",
   usage: "использование",
   scopedSlots: "Модифицированные слоты",
   asyncItems: "Асинхронные объекты",
   smallDots: "Малые точки",
   iconDots: "Значок точек",
   coloredDots: "Цветные точки",
   oppositeSlot: "Противоположный слот",
   denseAlert: "Плотное предупреждение",
   fixedTabs: "Фиксированные вкладки",
   rightAlignedTabs: "Выровненные по правому краю вкладки",
   content: "содержание",
   withSearch: "С поиском",
   iconsAndText: "Иконки и текст",
   grow: "расти",
   pagination: "пагинация",
   customIcons: "Пользовательские иконки",
   courses:"Курсы",
   coursesList:"Список курсов",
   courseDetail:"Курсы Подробнее",
   signIn:"Войти в систему",
   payment:"Оплата",
   LearnWithYourConvenience:"Учитесь с вашим удобством",
   management:"управление",
   design:"дизайн",
   development:"развитие",
   top:"верхний",
   new:"новый",
   trending:"Trending",
   bestseller:"Бестселлер",
   addToCart:"Добавить в корзину",
   popularInstructors:"Популярные Инструкторы",
   moreCoursesFromJamesColt:"Больше курсов от Джеймса Кольта",
   whatYoWillLearn:"Что вы узнаете",
   description:"Описание",
   aboutInstructor:"Об инструкторе",
   userSignIn:"Вход пользователя",
   guestCheckout:"Гостевой заказ",
   continueAsGuest:"Продолжить как Гость",
   paymentOptions:"Варианты оплаты",
   offerCode:"Код предложения",
   enterCardDetails:"Введите данные карты",
   submit:"Отправить",
   clear:"Очистить",
   makePayment:"Производить оплату",
   usage: "использование",
   withContent: "С содержанием",
   customHeight: "Пользовательская высота"
}
