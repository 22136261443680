//messages.js
export default {
    general: '일반',
    overview: '개요',
    customizer: '맞춤 설정 도구',
    features: '응용 분야',
    components: '풍모',
    applications: '구성 요소',
    salesAndVisitStats: '영업 및 방문 통계',
    orderAndProjectStats: '주문 및 프로젝트 통계',
    fitnessStats: '피트니스 통계',
    supportAndUsefulWidgets: '지원 및 유용한 위젯',
    dashboard: '계기반',
    dashboardOverview: '대시 보드 개요',
    dashboardv1: '대시 보드 V1',
    dashboardv2: 'Dashboard V2',
    widgets: '위젯',
    horizontalMenu: '수평 메뉴',
    pages: '페이지',
    gallery: '갱도',
    pricing: '가격',
    pricing1: '가격 1',
    pricing2: '가격 2',
    blank: '공백',
    session: '세션',
    signUp1: '가입 1',
    signUp2: '가입 2',
    login1: '로그인 1',
    login2: '로그인 2',
    lockScreen: '잠금 화면',
    uiElements: 'UI 요소',
    buttons: '버튼',
    cards: '카드',
    checkbox: '체크 박스',
    carousel: '회전 목마',
    chips: '작은 조각',
    datepicker: '날짜 선택기',
    dialog: '대화',
    grid: '그리드',
    input: '입력',
    list: '명부',
    menu: '메뉴',
    progress: '진행',
    radio: '라디오',
    select: '고르다',
    slider: '슬라이더',
    snackbar: '스낵바',
    tabs: '탭',
    toolbar: '툴바',
    tooltip: '툴팁',
    timepicker: '타임 피커',
    forms: '양식',
    formValidation: '양식 검증',
    stepper: '스테퍼',
    charts: '차트',
    vueChartjs: 'Vue Chartjs',
    vueEcharts: 'Vue Echarts',
    icons: '아이콘',
    themify: 'Themify',
    material: '자료',
    tables: '테이블',
    standard: '표준',
    slots: '슬롯',
    selectable: '선택 가능',
    searchRow: '행 검색',
    maps: '지도',
    googleMaps: '구글지도',
    leafletMaps: '전단지지도',
    jvectorMap: 'Jvector지도',
    inbox: '받은 편지함',
    users: '사용자',
    userProfile: '유저 프로필',
    usersList: '사용자 목록',
    calendar: '달력',
    editor: '편집자',
    quillEditor: '퀼 에디터',
    wYSIWYG: 'WYSIWYG',
    vue2Dragula: 'Vue2 Dragula',
    dragAndDrop: '끌어서 놓기',
    vueDraggable: 'Vue Draggable',
    draggableResizeable: '드래그 가능한 크기 조정 가능',
    chat: '잡담',
    todo: '할 것',
    modules: '모듈',
    user: '사용자',
    miscellaneous: '여러 가지 잡다한',
    promo: '프로모션',
    connections: '사이',
    follow: '따르다',
    unfollow: '팔로우 해제',
    basicValidation: '기본 유효성 검사',
    validationWithSubmitAndclear: '제출 및 취소로 유효성 검사',
    submit: '제출',
    clear: '명확한',
    step1: '1 단계',
    step2: '2 단계',
    step3: '3 단계',
    continue: '잇다',
    cancel: '취소',
    totalEarnings: '전체 수익',
    onlineRevenue: '온라인 수익',
    offlineRevenue: '오프라인 수익',
    marketingExpenses: '마케팅 비용',
    newCustomers: '신규 고객',
    visitAndSalesStatistics: '방문 및 판매 통계',
    collapse: '무너짐',
    reload: '다시로드',
    close: '닫기',
    bandwidthUsage: '대역폭 사용량',
    shoppingCart: '쇼핑 카트',
    totalDownloads: '총 다운로드 수',
    productSales: '제품 판매',
    projectStatus: '프로젝트 상태',
    averageSteps: '평균 단계',
    todaysDistance: '오늘의 거리',
    todaysStep: '오늘의 걸음',
    todaysGoal: '오늘의 목표',
    calories: '칼로리',
    weeklySummary: '주간 요약',
    supportTickets: '티켓 지원',
    todoList: '할 일 목록',
    newsletterCampaign: '뉴스 레터 캠페인',
    currentVisitors: '현재 방문자',
    newMembers: '신입 회원',
    addNewBlog: '새 블로그 추가',
    add: '더하다',
    logOut: '로그 아웃',
    totalAppMemory: '총 앱 메모리',
    totalMemoryUsed: '사용 된 총 메모리',
    unreadMail: '12 읽지 않은 메일',
    feedback: '피드백',
    flatButton: '플랫 버튼',
    raisedButton: '제기 된 버튼',
    gradientButton: '그라디언트 버튼',
    buttonDropdownVariants: '버튼 드롭 다운 변형',
    buttonToggle: '버튼 토글',
    icon: '상',
    normal: '표준',
    disabled: '장애인',
    floating: '떠 있는',
    loaders: '로더',
    sizing: '크기 조정',
    outline: '개요',
    round: '일주',
    block: '블록',
    search: '수색',
    checkboxesBoolean: '체크 박스 부울',
    checkboxesArray: '체크 박스 배열',
    checkboxesStates: '체크 박스 - 상태',
    checkboxesColors: '확인란 - 색상',
    switches: '스위치',
    default: '태만',
    customTransition: '사용자 정의 전환',
    customDelimiter: '사용자 정의 구분 기호',
    hideControls: '컨트롤 숨기기',
    hideDelimiters: '구획 문자 구분 기호 숨기기',
    colored: '색깔',
    label: '상표',
    closeable: '닫기 가능',
    datePickerHorizontal: '날짜 선택 도구 가로형',
    datePickerVertical: '날짜 선택 도구 세로',
    datePickersInDialogAndMenu: '날짜 선택 도구 - 대화 상자 및 메뉴에서',
    datePickersWithModal: '모달을 사용한 날짜 선택 도구',
    datePickersFormattingDate: '날짜 선택기 - 날짜 형식 지정',
    simpleDialogs: '간단한 대화 상자',
    withoutActivator: '액티베이터가없는 경우',
    scrollable: '스크롤 가능',
    listOfAllMaterialIcons: '모든 재료 아이콘 목록',
    arrowsAndDirectionsIcons: '화살표 및 방향 아이콘',
    webAppIcons: '웹 응용 프로그램 아이콘',
    CONTROLICONS: '제어 아이콘',
    TEXTEDITOR: '텍스트 에디터',
    LAYOUTICONS: '레이아웃 아이콘',
    BRANDICONS: '브랜드 아이콘',
    newClients: '신규 고객',
    recurringClients: '되풀이 클라이언트',
    bounceRate: '이탈률',
    pageViews: '페이지 뷰',
    usefulWidgets: '유용한 위젯',
    sale: '판매',
    sales: '매상',
    invoices: '인보이스',
    referrals: '추천',
    serverLoad: '서버로드',
    recentSale: '최근 판매',
    supportRequest: '지원 요청',
    productSale: '제품 판매',
    viewAll: '모두보기',
    barChart: '막대 차트',
    polarAreaChart: '극지방 차트',
    lineChart: '선형 차트',
    radarChart: '레이더 차트',
    doughnutChart: '도넛 차트',
    bubbleChart: '버블 차트',
    gradientLineChart: '그라데이션 선 차트',
    pieChart: '파이 차트',
    funnelChart: '깔때기 형 차트',
    loginNow: '지금 로그인하십시오.',
    createAccount: '계정 만들기',
    termsOfService: '서비스 약관',
    bySigningUpYouAgreeTo: '가입하는 것에 동의합니다.',
    loginToAdmin: '관리자에게 로그인',
    enterUsernameAndPasswordToAccessControlPanelOf: '제어판에 액세스하려면 사용자 이름과 비밀번호를 입력하십시오.',
    havingAnAccount: '계정이 있으십니까?',
    quickLinks: '빠른 링크',
    plans: '계획',
    chooseThePlanThatWorksForYou: '자신에게 적합한 계획을 선택하십시오.',
    monthly: '월간 간행물',
    yearlyGet2MonthExtra: '매년 (2 개월 추가)',
    basic: '기본',
    pro: '찬성',
    advanced: '많은',
    basicFree: '기본 (무료)',
    startToBasic: '기본 시작',
    upgradeToPro: '프로로 업그레이드하십시오.',
    upgradeToAdvance: '사전 승급',
    comparePlans: '계획 비교',
    frequentlyAskedQuestions: '자주 묻는 질문',
    defaultInput: '기본 입력',
    activator: '액티베이터',
    hover: '가리 키다',
    menus: '메뉴',
    indeterminate: '불확정',
    sizeAndWidth: '크기 및 너비',
    rotate: '회전',
    determinate: '한정된',
    buffer: '완충기',
    radiosDefault: '라디오 기본값',
    radiosDirectionRow: '전파 방향 행',
    radiosDirectionColumn: '라디오 방향 열',
    radiosColors: '라디오 - 색상',
    switchesColors: '스위치 - 색상',
    continuous: '마디 없는',
    discrete: '이산',
    customColors: '맞춤 색상',
    editableNumericValue: '편집 가능한 숫자 값',
    contextualSnackbar: '문맥 스낵바',
    showSnackbar: '스낵바 표시',
    centeredTabs: '가운데 맞춤 탭',
    toolbarTabs: '툴바 탭',
    theDefaultColorToolbar: '기본 색상 도구 모음',
    appBar: '앱 바',
    appBarWithExtension: '확장 기능이있는 앱 바',
    visibility: '시계',
    top: '상단',
    right: '권리',
    bottom: '바닥',
    left: '왼쪽',
    toggle: '비녀장',
    timePicker: '시간 선택기',
    timePickerInDialogAndMenu: '시간 선택기 - 대화 상자 및 메뉴에서',
    itemsAndHeaders: '항목 및 헤더',
    selectableRows: '선택 가능한 행',
    headerCell: '머리글 셀',
    expand: '넓히다',
    recentChat: '최근 채팅',
    previousChats: '이전 채팅',
    addNewItems: '새 항목 추가',
    addToDo: '할 일 추가',
    yes: '예',
    ok: '승인',
    activeUsers: '활성 사용자',
    addNew: '새로운 걸 더하다',
    readMore: '더 읽기',
    assignNow: '지금 할당',
    totalRequest: '총 요청',
    new: '새로운',
    pending: '대기중인',
    update: '최신 정보',
    updated10MinAgo: '10 시간 전에 업데이트 됨',
    addNewCustomer: '신규 고객 추가',
    name: '이름',
    email: '이메일',
    editMember: '회원 수정',
    scheduleDate: '일정 날짜',
    title: '표제',
    newEmails: '새 이메일',
    newEmail: '새 이메일',
    employeePayroll: '직원 급여',
    forMostOfTheUsers: '대부분의 사용자에게',
    choosePlan: '플랜 선택',
    mega: '메가',
    master: '석사',
    forDeveloper: '개발자 용',
    forLargeEnterprises: '대기업의 경우',
    composeEmail: '이메일을 쓰다',
    mailboxes: '사서함',
    folders: '폴더',
    inbox5: '받은 편지함 (5)',
    draft: '초안',
    starred: '별표 표시된 항목',
    sentMessages: '보낸 메시지',
    spam: '스팸',
    delete: '지우다',
    work: '작업',
    personal: '개인적인',
    manageFolders: '폴더 관리',
    contacts: '콘택트 렌즈',
    settings: '설정',
    themeOptions: '테마 옵션',
    darkMode: '다크 모드',
    collapseSidebar: '축소 사이드 바',
    boxLayout: '상자 레이아웃',
    rtlLayout: 'RTT 레이아웃',
    backgroundImage: '배경 이미지',
    sidebarFilters: '사이드 바 필터',
    danger: '위험',
    primary: '행성',
    warning: '경고',
    success: '성공',
    info: '정보',
    dailySales: '일일 판매',
    trafficChannel: '트래픽 채널',
    campaignPerformance: '캠페인 실적',
    goToCampaign: '캠페인으로 이동',
    fullScreen: '전체 화면',
    ecommerce: '전자 상거래',
    shop: '가게',
    cart: '카트',
    total: '합계',
    totalPrice: '총 가격',
    viewCart: '장바구니보기',
    checkout: '점검',
    apply: '대다',
    noItemsFound: '제품을 찾지 못했습니다',
    billingDetails: '결제 세부 정보',
    placeOrder: '주문하기',
    addNewCard: '새 카드 추가',
    devicesShare: '기기 공유',
    customerOverview: '고객 개요',
    reviews: '리뷰',
    weeklySales: '주간 판매',
    recentOrders: '최근 주문들',
    categorySales: '카테고리 판매',
    webAnalytics: '웹 분석',
    topSelling: '인기 판매',
    social: '사회적인',
    newPost: '새로운 게시물',
    publish: '게시',
    magazine: '매거진',
    editUser: '사용자 편집',
    addNewUser: '새 사용자 추가',
    dark: '어두운',
    light: '빛',
    recentUsers: '최근 사용자',
    activityAroundWorld: '세계 일주 활동',
    adsIncome: '광고 수입',
    recentComments: '최근 댓글',
    recentNotifications: '최근 알림',
    messages: '메시지',
    edit: '편집하다',
    topAuthors: '최고 저자',
    marketingCampaign: '마케팅 캠페인',
    articles: '조항',
    websiteTraffic: '웹 사이트 트래픽',
    agency: '대리점',
    recent: '충적세',
    trending: '인기 급상승',
    totalPageViews: '총 페이지 뷰',
    impressions: '노출 수',
    deviceSeparations: '장치 분리',
    browserStatics: '브라우저 통계',
    totalDownloading: '총 다운로드',
    notifications: '알림',
    totalBookmarked: '총 즐겨 찾기',
    itemsDownloads: '상품 다운로드',
    itemsUploaded: '업로드 된 항목',
    totalAnalytics: '총 애널리틱스',
    hotKeywords: '인기 키워드',
    MaleUsers: '남성 사용자',
    serverStatus: '서버 상태',
    purchaseVuely: 'Vuely 구매',
    letsGetInTouch: '손을 잡아라.',
    maleUsers: '남성 사용자',
    femaleUsers: '여성 사용자',
    androidUsers: 'Android 사용자',
    iOSUsers: 'iOS 사용자',
    advance: '전진',
    routerAnimation: '라우터 애니메이션',
    salesAndEarning: '판매 및 수익',
    netProfit: '순이익',
    totalExpences: '총 배출량',
    photos: '사진들',
    adCampaignPerfomance: '광고 캠페인 실적',
    profitShare: '이익 공유',
    topSellingTheme: '인기 판매 테마',
    newRequest: '새로운 요청',
    followers: '추종자',
    mailbox: '사서함',
    sent: '전송 됨',
    birthdays: '생일',
    events: '이벤트',
    newsletter: '뉴스 레터',
    lastMonth: '지난 달',
    allTime: '모든 시간',
    composeNewEmail: '새로운 이메일 작성',
    activity: '활동',
    message: '메시지',
    selectTheme: '주제 선택',
    continueShopping: '쇼핑을 계속',
    proceedToCheckout: 'Checkout으로 진행하십시오.',
    headerFilters: '헤더 필터',
    blog: '블로그',
    signUp: '가입하기',
    login: '로그인',
    news: '뉴스',
    topHeadlines: '톱 헤드 라인',
    visitors: '방문객',
    subscribers: '구독자',
    twitterFeeds: 'Twitter 피드',
    extensions: '확장 프로그램',
    imageCropper: '이미지 자르기 도구',
    videoPlayer: '비디오 플레이어',
    dropzone: '드롭 존',
    baseConfig: '기본 구성',
    audioTrackAndPlaysInline: '오디오 트랙 및 인라인 재생',
    hlsLive: 'HLS 라이브',
    forgotPassword: '비밀번호를 잊으 셨나요',
    resetPassword: '암호를 재설정',
    backToSignIn: '로그인하려면 돌아 가기',
    enterYourEmailToSendYouAResetLink: '재설정 링크를 보내려면 이메일을 입력하십시오.',
    pleaseEnterYourPasswordToReset: '재설정 할 비밀번호를 입력하십시오.',
    saas: '사스',
    overallTrafficStatus: '전체 트래픽 상태',
    projectManagement: '프로젝트 관리',
    projectTaskManagement: '프로젝트 작업 관리',
    totalSales: '총 매출',
    simple: '단순한',
    transitions: '전환',
    tax: '세',
    expenses: '경비',
    images: '이미지',
    ratings: '평점',
    containAndCover: '포함 및 커버',
    height: '신장',
    fixedRatio: '고정 비율',
    gradients: '그라디언트',
    sizeVariants: '크기 변형',
    newIcons: '새 아이콘',
    webApplication: '웹 응용 프로그램',
    person: '사람',
    file: '파일',
    comment: '논평',
    form: '형태',
    hardware: '하드웨어',
    directional: '방향',
    mapAliases: '지도 (별칭)',
    dateAndTime: '날짜와 시간',
    groups: "여러 떼",
    buttonGroups: "버튼 그룹",
    itemGroups: "품목 그룹",
    windows: "Windows",
    onBoarding: "탑승 중",
    accountCreation: "계정 생성",
    treeview: "트 리뷰",
    customSelectableIcons: "사용자가 선택 가능한 아이콘",
    timelines: "타임 라인",
    usage: "용법",
    scopedSlots: "스코프 슬롯",
    asyncItems: "비동기 항목",
    smallDots: "작은 점",
    iconDots: "아이콘 도트",
    coloredDots: "색깔 점",
    oppositeSlot: "반대쪽 슬롯",
    denseAlert: "조밀 한 경고",
    fixedTabs: "고정 탭",
    rightAlignedTabs: "오른쪽 맞춤 탭",
    content: "함유량",
    withSearch: "검색 기능 포함",
    iconsAndText: "아이콘과 텍스트",
    grow: "성장하다",
    pagination: "쪽수 매기기",
    customIcons: "사용자 정의 아이콘",
    courses:"행동",
    coursesList:"코스리스트",
    courseDetail:"코스 세부 정보",
    signIn:"로그인",
    payment:"지불",
    LearnWithYourConvenience:"편의 제공",
    management:"조치",
    design:"디자인",
    development:"개발",
    top:"상단",
    new:"새로운",
    trending:"인기 급상승",
    bestseller:"베스트셀러",
    addToCart:"장바구니에 담기",
    popularInstructors:"인기 강사",
    moreCoursesFromJamesColt:"James Colt의 추가 코스",
    whatYoWillLearn:"배우게 될 것",
    description:"기술",
    aboutInstructor:"강사 소개",
    userSignIn:"사용자 로그인",
    guestCheckout:"고객 체크 아웃",
    continueAsGuest:"게스트로 계속하기",
    paymentOptions:"지불 옵션",
    offerCode:"쿠폰 코드",
    enterCardDetails:"카드 정보 입력",
    submit:"제출",
    clear:"명확한",
    makePayment:"지불하기",
    usage: "용법",
    withContent: "내용 포함",
    customHeight: "맞춤 높이"
}
