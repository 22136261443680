import * as T from './types'
import axios from 'axios'
import {api} from '@/components/apiurls'
import {authHeader} from '@/components/global'
import router from '../../../router';

export const loadCustomerList = ({commit}, payload) => {
  
  return axios.post(api['CUST_LIST'], payload, authHeader()).then (res => {
    if (res.data.code == 1)
    {
      commit(T.SET_CUSTOMER_LIST, res.data.data)
    }
    else 
    {
      commit(T.SET_ERROR_SEARCH_ORG, res.data.msg)
    }
  })
  .catch(error => alert("Error login: " + error))
}

export const loadVesselList = ({commit}, payload) => {
  
  return axios.post(api['VESSEL_LIST'], payload, authHeader()).then (res => {
    if (res.data.code == 1)
    {
      commit(T.SET_VESSEL_LIST, res.data.data)
    }
    else 
    {
      commit(T.SET_ERROR_SEARCH_ORG, res.data.msg)
    }
  })
  .catch(error => alert("Error login: " + error))
}

export const loadDepartmentList = ({commit}, payload) => {
  
  return axios.post(api['DEPARTMENT_LIST'], payload, authHeader()).then (res => {
    if (res.data.code == 1)
    {
      commit(T.SET_DEPT_LIST, res.data.data)
    }
    else 
    {
      commit(T.SET_ERROR_SEARCH_ORG, res.data.msg)
    }
  })
  .catch(error => alert("Error login: " + error))
}

export const loadProductList = ({commit}, payload) => {
  
  return axios.post(api['PRODUCT_LIST'], payload, authHeader()).then (res => {
    if (res.data.code == 1)
    {
      commit(T.SET_PRODUCT_LIST, res.data.data)
      // store in localstorage // exceed quota
      // localStorage.setItem('productOptions',JSON.stringify(res.data.data));
      // localStorage.productOptions = res.data.data
    }
    else 
    {
      commit(T.SET_ERROR_SEARCH_ORG, res.data.msg)
    }
  })
  .catch(error => alert("Error login: " + error))
}

export const loadVendorList = ({commit}, payload) => {
  
  return axios.post(api['VENDOR_LIST'], payload, authHeader()).then (res => {
    if (res.data.code == 1)
    {
      commit(T.SET_VENDOR_LIST, res.data.data)
    }
    else 
    {
      commit(T.SET_ERROR_SEARCH_ORG, res.data.msg)
    }
  })
  .catch(error => alert("Error login: " + error))
}