import _ from 'lodash'
import Vue from 'vue'

export default {
  // Customer
  getCustomerList: state => {
    return state.customerList
  },
  // Vessel
  getVesselList: state => {
    return state.vesselList
  },
  // Department
  getDepartmentList: state => {
    return state.departmentList
  },
  // Product
  getProductList: state => {
    // console.log(localStorage)
    return state.productList
  },
  // Vendor
  getVendorList: state => {
    return state.vendorList
  },
}
