//messages.js
export default {
  general: '一般',
  overview: '概观',
  customizer: '定制',
  applications: '应用',
  features: '特征',
  components: '组件',
  salesAndVisitStats: '销售和访问统计',
  orderAndProjectStats: '订单和项目统计',
  fitnessStats: '健身统计',
  supportAndUsefulWidgets: '支持和有用的小部件',
  dashboard: '仪表板',
  dashboardOverview: '仪表板概述',
  dashboardv1: '仪表板V1',
  dashboardv2: '仪表板V2',
  widgets: '小工具',
  horizontalMenu: '水平菜单',
  pages: '网页',
  gallery: '画廊',
  pricing: '价钱',
  pricing1: '定价1',
  pricing2: '定价2',
  blank: '空白',
  session: '会议',
  signUp1: '注册1',
  signUp2: '注册2',
  login1: '登录1',
  login2: '登录2',
  lockScreen: '锁屏',
  uiElements: 'UI元素',
  buttons: '纽扣',
  cards: '牌',
  checkbox: '复选框',
  carousel: '圆盘传送带',
  chips: '芯片',
  datepicker: '日期选择器',
  dialog: '对话',
  grid: '格',
  input: '输入',
  list: '名单',
  menu: '菜单',
  progress: '进展',
  radio: '无线电',
  select: '选择',
  slider: '滑块',
  snackbar: '小吃店',
  tabs: '标签',
  toolbar: '工具栏',
  tooltip: '提示',
  timepicker: 'Timepicker',
  forms: '形式',
  formValidation: '表格验证',
  stepper: '步进',
  charts: '图表',
  vueChartjs: 'Vue Chartjs',
  vueEcharts: 'Vue Echarts',
  icons: '图标',
  themify: 'Themify',
  material: '材料',
  tables: '表',
  standard: '标准',
  slots: '老虎机',
  selectable: '可选',
  searchRow: '搜索行',
  maps: '地图',
  googleMaps: '谷歌地图',
  leafletMaps: '宣传单地图',
  jvectorMap: 'Jvector地图',
  inbox: '收件箱',
  users: '用户',
  userProfile: '用户资料',
  usersList: '用户列表',
  calendar: '日历',
  editor: '编辑',
  quillEditor: '羽毛笔编辑器',
  wYSIWYG: '所见即所得',
  vue2Dragula: 'Vue2 Dragula',
  dragAndDrop: '拖放',
  vueDraggable: 'Vue Draggable',
  draggableResizeable: '可拖动的可调整大小',
  chat: '聊',
  todo: '去做',
  modules: '模块',
  user: '用户',
  miscellaneous: '杂',
  promo: '促销',
  connections: '连接',
  follow: '跟随',
  unfollow: '取消关注',
  basicValidation: '基本验证',
  validationWithSubmitAndclear: '验证提交和清除',
  submit: '提交',
  clear: '明确',
  step1: '步骤1',
  step2: '第2步',
  step3: '第3步',
  continue: '继续',
  cancel: '取消',
  totalEarnings: '总收入',
  onlineRevenue: '在线收入',
  offlineRevenue: '离线收入',
  marketingExpenses: '营销费用',
  newCustomers: '新客户',
  visitAndSalesStatistics: '访问和销售统计',
  collapse: '坍方',
  reload: '刷新',
  close: '关',
  bandwidthUsage: '带宽使用',
  shoppingCart: '购物车',
  totalDownloads: '总下载量',
  productSales: '产品销售',
  projectStatus: '项目状态',
  averageSteps: '平均步数',
  todaysDistance: "今天的距离",
  todaysStep: "今天的步骤",
  todaysGoal: "今天的目标",
  calories: '卡路里',
  weeklySummary: '每周摘要',
  supportTickets: '支持门票',
  todoList: '待办事项清单',
  newsletterCampaign: '通讯活动',
  currentVisitors: '现有访客',
  newMembers: '新成员',
  addNewBlog: '添加新博客',
  add: '加',
  logOut: '登出',
  totalAppMemory: '应用内存总量',
  totalMemoryUsed: '使用的总内存',
  unreadMail: '12未读邮件',
  feedback: '反馈',
  flatButton: '平面按钮',
  raisedButton: '凸起的按钮',
  gradientButton: '渐变按钮',
  buttonDropdownVariants: '按钮下拉变种',
  buttonToggle: '按钮切换',
  icon: '图标',
  normal: '正常',
  disabled: '残',
  floating: '漂浮的',
  loaders: '装载机',
  sizing: '浆纱',
  outline: '大纲',
  round: '回合',
  block: '块',
  search: '搜索',
  checkboxesBoolean: '复选框布尔值',
  checkboxesArray: '复选框数组',
  checkboxesStates: '复选框 - 状态',
  checkboxesColors: '复选框 - 颜色',
  switches: '开关',
  default: '默认',
  customTransition: '自定义转换',
  customDelimiter: '自定义分隔符',
  hideControls: '隐藏控件',
  hideDelimiters: '隐藏分隔符',
  colored: '有色',
  label: '标签',
  closeable: '可关闭',
  datePickerHorizontal: '日期选择器水平',
  datePickerVertical: '日期选择器垂直',
  datePickersInDialogAndMenu: '日期选择器 - 在对话框和菜单中',
  datePickersWithModal: '采用模态的日期选择器',
  datePickersFormattingDate: '日期选择器 - 格式化日期',
  simpleDialogs: '简单的对话框',
  withoutActivator: '没有Activator',
  scrollable: '滚动',
  listOfAllMaterialIcons: '所有材料图标列表',
  arrowsAndDirectionsIcons: 'ARROWS & DIRECTION ICONS',
  webAppIcons: 'WEB APP图标',
  CONTROLICONS: '控制图标',
  TEXTEDITOR: '文本编辑器',
  LAYOUTICONS: '布局图标',
  BRANDICONS: '品牌图标',
  newClients: '新客户',
  recurringClients: '经常性客户',
  bounceRate: '跳出率',
  pageViews: '页面浏览量',
  usefulWidgets: '有用的小部件',
  sale: '拍卖',
  sales: '销售',
  invoices: '发票',
  referrals: '介绍人',
  serverLoad: '服务器负载',
  recentSale: '近期促销',
  supportRequest: '支持请求',
  productSale: '产品销售',
  viewAll: '查看全部',
  barChart: '条形图',
  polarAreaChart: '极地图',
  lineChart: '折线图',
  radarChart: '雷达图',
  doughnutChart: '甜甜圈图表',
  bubbleChart: '气泡图',
  gradientLineChart: '渐变折线图',
  pieChart: '饼形图',
  funnelChart: '漏斗图',
  loginNow: '现在登录',
  createAccount: '创建帐号',
  termsOfService: '服务条款',
  bySigningUpYouAgreeTo: '通过注册即表示您同意',
  loginToAdmin: '登录管理员',
  enterUsernameAndPasswordToAccessControlPanelOf: '输入用户名和密码以访问控制面板',
  havingAnAccount: '有账户？',
  quickLinks: '快速链接',
  plans: '计划',
  chooseThePlanThatWorksForYou: '选择适合您的计划。',
  monthly: '每月一次',
  yearlyGet2MonthExtra: '每年（额外增加2个月）',
  basic: '基本',
  pro: '临',
  advanced: '高级',
  basicFree: '基本（免费）',
  startToBasic: '开始基本',
  upgradeToPro: '升级到专业版。',
  upgradeToAdvance: '升级到先进',
  comparePlans: '比较计划',
  frequentlyAskedQuestions: '经常问的问题',
  defaultInput: '默认输入',
  activator: '活化剂',
  hover: '徘徊',
  menus: '菜单',
  indeterminate: '不定',
  sizeAndWidth: '尺寸和宽度',
  rotate: '旋转',
  determinate: '确定的',
  buffer: '缓冲',
  radiosDefault: '无线电默认值',
  radiosDirectionRow: '无线电方向行',
  radiosDirectionColumn: '无线电方向列',
  radiosColors: '收音机 - 颜色',
  switchesColors: '开关 - 颜色',
  continuous: '连续',
  discrete: '分离',
  customColors: '自定义颜色',
  editableNumericValue: '可编辑的数值',
  contextualSnackbar: '语境快餐吧',
  showSnackbar: '显示Snackbar',
  centeredTabs: '中心标签',
  toolbarTabs: '工具栏标签',
  theDefaultColorToolbar: '默认颜色工具栏',
  appBar: 'App Bar',
  appBarWithExtension: '应用栏扩展',
  visibility: '能见度',
  top: '最佳',
  right: '对',
  bottom: '底部',
  left: '剩下',
  toggle: '切换',
  timePicker: '时间选择器',
  timePickerInDialogAndMenu: '时间选择器 - 在对话框和菜单中',
  itemsAndHeaders: '物品和标题',
  selectableRows: '可选行',
  headerCell: '标题单元格',
  expand: '扩大',
  recentChat: '最近的聊天',
  previousChats: '以前的聊天',
  addNewItems: '添加新项目',
  addToDo: '添加待办事项',
  yes: '是',
  ok: '好',
  activeUsers: '活跃用户',
  addNew: '添新',
  readMore: '阅读更多',
  assignNow: '立即分配',
  totalRequest: '总请求',
  new: '新',
  pending: '有待',
  update: '更新',
  updated10MinAgo: '10分钟前更新',
  addNewCustomer: '添加新客户',
  name: '名称',
  email: '电子邮件',
  editMember: '编辑会员',
  scheduleDate: '安排日期',
  title: '标题',
  newEmails: '新的电子邮件',
  newEmail: '新邮件',
  employeePayroll: '员工薪资l',
  forMostOfTheUsers: '对于大多数用户',
  choosePlan: '选择计划',
  mega: '兆丰',
  master: '主',
  forDeveloper: '对于开发者',
  forLargeEnterprises: '适用于大型企业',
  composeEmail: '写邮件',
  mailboxes: '邮箱',
  folders: '文件夹',
  inbox5: '收件箱（5）',
  draft: '草案',
  starred: '主演',
  sentMessages: '发送信息',
  spam: '垃圾邮件',
  delete: '删除',
  work: '工作',
  personal: '个人',
  manageFolders: '管理文件夹',
  contacts: '往来',
  settings: '设置',
  themeOptions: '主题选项s',
  darkMode: '暗模式',
  collapseSidebar: '折叠边栏',
  boxLayout: '框布局',
  rtlLayout: 'Rtl布局',
  backgroundImage: '背景图',
  sidebarFilters: '边栏过滤器',
  danger: '危险',
  primary: '主',
  warning: '警告',
  success: '成功',
  info: '信息',
  dailySales: '每日销售',
  trafficChannel: '交通频道',
  campaignPerformance: '广告系列效果',
  goToCampaign: '转到广告系列',
  fullScreen: '全屏',
  ecommerce: '电子商务',
  shop: '店',
  cart: '大车',
  total: '总',
  totalPrice: '总价',
  viewCart: '查看购物车',
  checkout: '查看',
  apply: '应用',
  noItemsFound: '未找到任何项目',
  billingDetails: '结算明细',
  placeOrder: '下订单',
  addNewCard: '添加新卡',
  devicesShare: '设备共享',
  customerOverview: '客户概述',
  reviews: '评测',
  weeklySales: '每周销售',
  recentOrders: '最近的订单',
  categorySales: '类别销售',
  webAnalytics: '网络分析',
  topSelling: '畅销',
  social: '社会',
  newPost: '最新帖子',
  publish: '发布',
  magazine: '杂志',
  editUser: '编辑用户',
  addNewUser: '添加新用户',
  dark: '黑暗',
  light: '光',
  recentUsers: '最近的用户',
  activityAroundWorld: '世界各地的活动',
  adsIncome: '广告收入',
  recentComments: '最近的评论',
  recentNotifications: '最近的通知',
  messages: '消息',
  edit: '编辑',
  topAuthors: '热门作者',
  marketingCampaign: '营销活动',
  articles: '用品',
  websiteTraffic: '网站流量',
  agency: '机构',
  recent: '最近',
  trending: '趋势',
  totalPageViews: '总页面浏览量',
  impressions: '印象',
  deviceSeparations: '设备分离',
  browserStatics: '浏览统计',
  totalDownloading: '总下载量',
  notifications: '通知',
  totalBookmarked: '已加总书签',
  itemsDownloads: '项目下载',
  itemsUploaded: '项目已上传',
  totalAnalytics: '总分析',
  hotKeywords: '热门关键词',
  MaleUsers: '男性用户',
  serverStatus: '服务器状态',
  purchaseVuely: '购买Vuely',
  letsGetInTouch: '让我们取得联系',
  maleUsers: '男性用户',
  femaleUsers: '女性用户',
  androidUsers: 'Android用户',
  iOSUsers: 'iOS用户',
  advance: '提前',
  routerAnimation: '路由器动画',
  salesAndEarning: '销售和收入',
  netProfit: '净利',
  totalExpences: '总费用',
  photos: '相片',
  adCampaignPerfomance: '广告系列效果',
  profitShare: '分享利益',
  topSellingTheme: '畅销主题',
  newRequest: '新请求',
  followers: '关注',
  mailbox: '邮箱',
  sent: '发送',
  birthdays: '生日',
  events: '活动',
  newsletter: '通讯',
  lastMonth: '上个月',
  allTime: '整天',
  composeNewEmail: '撰写新电子邮件',
  activity: '活动',
  message: '信息',
  selectTheme: '选择主题',
  continueShopping: '继续购物',
  proceedToCheckout: '进行结算',
  headerFilters: '标头过滤器',
  blog: "博客",
  signUp: "注册",
  login: "登录",
  news: "新闻",
  topHeadlines: "头条新闻",
  visitors: "游客",
  subscribers: "认购",
  twitterFeeds: "推特供稿",
  extensions: "扩展",
  imageCropper: "图像裁剪器",
  videoPlayer: "视频播放器",
  dropzone: "拖放区",
  baseConfig: "基本配置",
  audioTrackAndPlaysInline: "音轨和播放内联",
  hlsLive: "HLS Live",
  forgotPassword: "忘记密码",
  resetPassword: "重设密码",
  backToSignIn: "返回登录",
  enterYourEmailToSendYouAResetLink: "输入您的电子邮件，向您发送重置链接",
  pleaseEnterYourPasswordToReset: "请输入要重置的密码",
  saas: "SAAS",
  overallTrafficStatus: "整体交通状况",
  projectManagement: "项目管理",
  projectTaskManagement: "项目任务管理",
  totalSales: "总销售额",
  simple: "简单",
  transitions: "转变",
  tax: "税",
  expenses: "花费",
  images: "图片",
  ratings: "评级",
  containAndCover: '包含和覆盖',
  height: '高度',
  fixedRatio: '固定比率',
  gradients: '渐变',
  sizeVariants: '大小变体',
  newIcons: '新图标',
  webApplication: 'Web应用程序',
  person: '人',
  file: '文件',
  comment: '评论',
  form: '形成',
  hardware: '硬件',
  directional: '定向',
  mapAliases: '地图（别名）',
  dateAndTime: '日期和时间',
  groups: "组",
  buttonGroups: "按钮组",
  itemGroups: "物品组",
  windows: "视窗",
  onBoarding: "在登机",
  accountCreation: "帐户创建",
  treeview: "树视图",
  customSelectableIcons: "自定义可选图标",
  timelines: "时间线",
  usage: "用法",
  scopedSlots: "Scoped Slots",
  asyncItems: "异步项目",
  smallDots: "小点",
  iconDots: "图标点",
  coloredDots: "彩色圆点",
  oppositeSlot: "对面的插槽",
  denseAlert: "密集警报",
  fixedTabs: "固定标签",
  rightAlignedTabs: "右对齐标签",
  content: "内容",
  withSearch: "随着搜索",
  iconsAndText: "图标和文本",
  grow: "增长",
  pagination: "分页",
  customIcons: "自定义图标",
  courses:"课程",
  coursesList:"课程列表",
  courseDetail:"课程详情",
  signIn:"登入",
  payment:"付款",
  LearnWithYourConvenience:"学习方便",
  management:"管理",
  design:"设计",
  development:"发展",
  top:"最佳",
  new:"新",
  trending:"趋势",
  bestseller:"畅销书",
  addToCart:"添加到购物车",
  popularInstructors:"受欢迎的教练",
  moreCoursesFromJamesColt:"James Colt的更多课程",
  whatYoWillLearn:"你将学到什么",
  description:"描述",
  aboutInstructor:"关于讲师",
  userSignIn:"用户登录",
  guestCheckout:"访客结帐",
  continueAsGuest:"以访客继续",
  paymentOptions:"付款方式",
  offerCode:"优惠代码",
  enterCardDetails:"输入卡详细信息",
  submit:"提交",
  clear:"明确",
  makePayment:"付款",
  usage: "用法",
  withContent: "随着内容",
  customHeight: "自定义高度"
}
