/**
 * login Module
 */
import Vue from 'vue'
//import firebase from 'firebase/app';
import Nprogress from 'nprogress';
import router from '../../../router';

import axios from 'axios'
import {api} from '@/components/apiurls'

const state = {
    user: {}    // localStorage.getItem('user')
}

// getters
const getters = {
    user: state => {
        return state.user;
    }
}

// mutations
const mutations = {
    setUser (state, user)
    {
        if (user) 
        {
          state.user = user;  
        } 
    },
    loginUser(state) {
        Nprogress.start();
    },
    loginUserSuccess(state, user) {
        state.user = user;
        localStorage.setItem('user',JSON.stringify(user));
        state.isUserSigninWithAuth0 = false
        router.push("/dashboard");
        setTimeout(function(){
            Vue.notify({
                group: 'loggedIn',
                type: 'success',
                text: 'User Logged In Success!'
            });
       },1500);
    },
    loginUserFailure(state, error) {
        Nprogress.done();
        Vue.notify({
            group: 'loggedIn',
            type: 'error',
            text: error.message
        });
    },
}

// actions
const actions = {
    login({commit}, payload) {
        const { user } = payload;
        
        commit('loginUser');
        axios.post(api['Login'], 
        {
            code: user.code,
            password: user.password
        })
        .then (res => {
            if (res.data.code == 1)
            {
                // localStorage.access_token = res.data.data.access_token
                // localStorage.organization_id = res.data.data.organization_id
                
                localStorage.access_token = res.data.data.access_token
                localStorage.auth_acl = res.data.data.acl
                var user_fullname = res.data.data.user_fullname
                localStorage.user_fullname = user_fullname
                localStorage.user_name = user_fullname
                localStorage.user_id = user_fullname
                commit('setUser', res.data.data);
                //console.log(res.data.data)
                //console.log(state.user)
                
                Nprogress.done();

                localStorage.setItem('user',JSON.stringify(state.user));

                // router.push("/app/dashboard");
                router.push("/dashboard");
                setTimeout(function(){
                    Vue.notify({
                        group: 'loggedIn',
                        type: 'success',
                        text: 'User Logged In Success!'
                    });
                },1500);
            }
            else 
            {
                this.errorMessage = res.data.msg
                this.$refs.staticModal.open()
            }
        })
        .catch(error => commit('loginUserFailure', error));
    },
    setUser: ({commit},user) => {

        commit(setUser, user);
    },
}

export default {
    state,
    getters,
    actions,
    mutations
}
