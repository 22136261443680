import * as actions from './actions'
import getters from './getters'
import mutations from './mutations'

const state = {
  // SQ
  SQList:[],
  SQ:{},
  SQSearchTerm:'',
  // SO
  SOList:[],
  SO:{},
  SOSearchTerm:'',
  // PO
  POList:[],
  PO:{},
  POSearchTerm:''
}

export default {
  state,
  actions,
  mutations,
  getters
}
