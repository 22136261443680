//messages.js
export default {
  general: 'Général',
  overview: 'Aperçu',
  customizer: 'Personnalisateur',
  components: 'Composants',
  applications: 'Applications',
  features: 'Fonctionnalités',
  salesAndVisitStats: 'Ventes et statistiques de visites',
  orderAndProjectStats: 'Stats de commandes et de projets',
  fitnessStats: 'Stats de remise en forme',
  supportAndUsefulWidgets: 'Support et Widgets utiles',
  dashboard: 'Tableau de bord',
  dashboardOverview: 'Présentation du tableau de bord',
  dashboardv1: 'Tableau de bord V1',
  dashboardv2: 'Tableau de bord V2',
  widgets: 'Widgets',
  horizontalMenu: 'Horizontal Menu',
  pages: 'Pages',
  gallery: 'Galerie',
  pricing: 'Prix',
  pricing1: 'prix 1',
  pricing2: 'prix 2',
  blank: 'Blanc',
  session: 'Session',
  signUp1: "S'inscrire 1",
  signUp2: "S'inscrire 2",
  login1: "S'identifier 1",
  login2: "S'identifier 2",
  lockScreen: 'Écran verrouillé',
  uiElements: "Éléments d'interface utilisateur",
  buttons: 'Boutons',
  cards: 'Cartes',
  checkbox: 'Case à cocher',
  carousel: 'Carrousel',
  chips: 'chips',
  datepicker: 'Sélecteur de date',
  dialog: 'Dialogue',
  grid: 'la grille',
  input: 'Contribution',
  list: 'liste',
  menu: 'Menu',
  progress: 'Le progrès',
  radio: 'Radio',
  select: 'Sélectionner',
  slider: 'Slider',
  snackbar: 'Snackbar',
  tabs: 'onglets',
  toolbar: "Barre d'outils",
  tooltip: "info-bulle",
  timepicker: 'Timepicker',
  forms: 'Formes',
  formValidation: 'Validation de formulaire',
  stepper: 'Stepper',
  charts: 'Graphiques',
  vueChartjs: 'Vue Chartjs',
  vueEcharts: 'Vue Echarts',
  icons: 'Icônes',
  themify: 'Thémifier',
  material: 'Matériel',
  tables: 'les tables',
  standard: 'la norme',
  slots: 'Slots',
  selectable: 'Sélectionnable',
  searchRow: 'Rechercher une ligne',
  maps: 'Plans',
  googleMaps: 'Google Maps',
  leafletMaps: 'Cartes de brochure',
  jvectorMap: 'Carte Jvector',
  inbox: 'Boîte de réception',
  users: 'Utilisateurs',
  user: 'Utilisateur',
  userProfile: "Profil de l'utilisateur",
  usersList: 'Liste des utilisateurs',
  calendar: 'Calendrier',
  editor: 'Éditeur',
  quillEditor: 'Éditeur de piquants',
  wYSIWYG: 'WYSIWYG',
  vue2Dragula: 'Vue2 Dragula',
  dragAndDrop: 'Glisser déposer',
  vueDraggable: 'Vue Draggable',
  draggableResizeable: 'Draggable redimensionnable',
  chat: 'Bavarder',
  todo: 'Todo',
  modules: 'Modules',
  miscellaneous: 'divers',
  promo: 'Promo',
  connections: 'Les liaisons',
  follow: 'Suivre',
  unfollow: 'Ne pas',
  basicValidation: 'Validation de base',
  validationWithSubmitAndclear: 'Validation avec soumettre et effacer',
  submit: 'Soumettre',
  clear: 'Clair',
  step1: 'Étape 1',
  step2: 'Étape 2',
  step3: 'Étape 3',
  continue: 'Continuer',
  cancel: 'Annuler',
  totalEarnings: 'Total des gains',
  onlineRevenue: 'Revenu en ligne',
  offlineRevenue: 'Revenus hors ligne',
  marketingExpenses: 'Les frais de commercialisation',
  newCustomers: 'nouveaux clients',
  visitAndSalesStatistics: 'Statistiques de visite et de vente',
  collapse: 'Effondrer',
  reload: 'Recharger',
  close: 'Fermer',
  bandwidthUsage: 'Utilisation de la bande passante',
  shoppingCart: 'Chariot',
  totalDownloads: 'Total des téléchargements',
  productSales: 'Ventes de produits',
  projectStatus: "L'état du projet",
  averageSteps: 'Étapes moyennes',
  todaysDistance: "Distance d'aujourd'hui",
  todaysStep: "L'étape du jour",
  todaysGoal: "L'objectif d'aujourd'hui",
  calories: 'Calories',
  weeklySummary: 'Résumé hebdomadaire',
  supportTickets: 'Billets de soutien',
  todoList: 'Liste de choses à faire',
  newsletterCampaign: 'Campagne de newsletter',
  currentVisitors: 'Visiteurs actuels',
  newMembers: 'Nouveaux membres',
  addNewBlog: 'Ajouter un nouveau blog',
  add: 'Ajouter',
  logOut: 'Connectez - Out',
  totalAppMemory: "Mémoire totale de l'application",
  totalMemoryUsed: 'Mémoire totale utilisée',
  unreadMail: '12 Courrier non lu',
  feedback: "Retour d'information",
  flatButton: 'Bouton plat',
  raisedButton: 'Bouton surélevé',
  gradientButton: 'Bouton de dégradé',
  buttonDropdownVariants: 'Bouton Dropdown Variantes',
  buttonToggle: 'Bouton bascule',
  icon: 'Icône',
  normal: 'Ordinaire',
  disabled: 'désactivé',
  floating: 'Flottant',
  loaders: 'Chargeurs',
  sizing: 'Dimensionnement',
  outline: 'Contour',
  round: 'Rond',
  block: 'Bloc',
  search: 'Chercher',
  checkboxesBoolean: 'Cases à cocher Boolean',
  checkboxesArray: 'Cases à cocher Array',
  checkboxesStates: 'Cases à cocher - États',
  checkboxesColors: 'Cases à cocher - Couleurs',
  switches: 'Commutateurs',
  default: 'Défaut',
  customTransition: 'Transition personnalisée',
  customDelimiter: 'Délimiteur personnalisé',
  hideControls: 'Masquer les contrôles',
  hideDelimiters: 'Masquer les délimiteurs',
  colored: 'Coloré',
  label: 'Étiquette',
  closeable: 'Fermable',
  datePickerHorizontal: 'Sélecteur de date Horizontal',
  datePickerVertical: 'Sélecteur de date vertical',
  datePickersInDialogAndMenu: 'Date Pickers- Dans le dialogue et le menu',
  datePickersWithModal: 'Sélecteurs de date avec modal',
  datePickersFormattingDate: 'Date Pickers - Date de formatage',
  simpleDialogs: 'Dialogues simples',
  withoutActivator: 'Sans activateur',
  scrollable: 'Défilement',
  listOfAllMaterialIcons: 'Liste de toutes les icônes de matériel',
  arrowsAndDirectionsIcons: 'FLÈCHES ET ORIENTATIONS DE DIRECTION',
  webAppIcons: "ICONS DE L'APPLICATION WEB",
  CONTROLICONS: 'Icônes de contrôle',
  TEXTEDITOR: 'ÉDITEUR DE TEXTE',
  LAYOUTICONS: 'ICONES DE LAYOUT',
  BRANDICONS: 'ICÔNES DE MARQUE',
  newClients: 'Nouveaux clients',
  recurringClients: 'Clients récurrents',
  bounceRate: 'Taux de rebond',
  pageViews: 'Pages vues',
  usefulWidgets: 'Widgets utiles',
  sale: 'Vente',
  sales: 'Ventes',
  invoices: 'Factures',
  referrals: 'Renvois',
  serverLoad: 'Charge du serveur',
  recentSale: 'Vente récente',
  supportRequest: 'Demande de soutien',
  productSale: 'Vente de produits',
  viewAll: 'VOIR TOUT',
  barChart: 'Diagramme à bandes',
  polarAreaChart: 'Carte de zone polaire',
  lineChart: 'Graphique en ligne',
  radarChart: 'Graphique radar',
  doughnutChart: 'Tableau de beignets',
  bubbleChart: 'Graphique à bulles',
  gradientLineChart: 'Diagramme de ligne de dégradé',
  pieChart: 'Camembert',
  funnelChart: 'Entonnoir',
  loginNow: 'Connecte-toi maintenant',
  createAccount: 'Créer un compte',
  termsOfService: "Conditions d'utilisation",
  bySigningUpYouAgreeTo: 'En vous inscrivant, vous acceptez',
  loginToAdmin: 'Connectez-vous à Admin',
  enterUsernameAndPasswordToAccessControlPanelOf: "Entrez un nom d'utilisateur et un mot de passe pour accéder au panneau de configuration de",
  havingAnAccount: 'Avoir un compte?',
  quickLinks: 'Liens rapides',
  plans: 'Des plans',
  chooseThePlanThatWorksForYou: 'Choisissez le plan qui fonctionne pour vous.',
  monthly: 'Mensuel',
  yearlyGet2MonthExtra: 'Annuel (obtenez 2 mois supplémentaires)',
  basic: 'De base',
  pro: 'Pro',
  advanced: 'Avancée',
  basicFree: 'Basic Gratuit',
  startToBasic: 'Début à la base',
  upgradeToPro: 'Passer à Pro.',
  upgradeToAdvance: "Mettre à niveau à l'avance",
  comparePlans: 'Comparer les plans',
  frequentlyAskedQuestions: 'Questions fréquemment posées',
  defaultInput: 'Entrée par défaut',
  activator: 'Activateur',
  hover: 'Flotter',
  menus: 'Menus',
  indeterminate: 'Indéterminé',
  sizeAndWidth: 'Taille et largeur',
  rotate: 'Tourner',
  determinate: 'Déterminé',
  buffer: 'Tampon',
  radiosDefault: 'Radios par défaut',
  radiosDirectionRow: 'Radios Direction Row',
  radiosDirectionColumn: 'Radios Direction Colonne',
  radiosColors: 'Radios - Couleurs',
  switchesColors: 'Commutateurs - Couleurs',
  continuous: 'Continu',
  discrete: 'Discret',
  customColors: 'Couleurs personnalisées',
  editableNumericValue: 'Valeur numérique modifiable',
  contextualSnackbar: 'Snackbar contextuel',
  showSnackbar: 'Afficher Snackbar',
  centeredTabs: 'Onglets centrés',
  toolbarTabs: "Onglets de la barre d'outils",
  theDefaultColorToolbar: "La barre d'outils couleur par défaut",
  appBar: 'App Bar',
  appBarWithExtension: "Barre d'applications avec extension",
  visibility: 'Visibilité',
  top: 'Haut',
  right: 'Droite',
  bottom: 'Bas',
  left: 'La gauche',
  toggle: 'Basculer',
  timePicker: 'Time Picker',
  timePickerInDialogAndMenu: 'Time Picker - Dans le dialogue et le menu',
  itemsAndHeaders: 'Articles et en-têtes',
  selectableRows: 'Lignes sélectionnables',
  headerCell: "Cellule d'en-tête",
  expand: 'Développer',
  recentChat: 'Chat récent',
  previousChats: 'Conversations précédentes',
  addNewItems: 'Ajouter de nouveaux éléments',
  addToDo: 'Ajouter à faire',
  yes: "Oui",
  ok: "D'accord",
  activeUsers: 'Utilisateurs actifs',
  addNew: 'Ajouter un nouveau',
  readMore: 'Lire la suite',
  assignNow: 'Assigner maintenant',
  totalRequest: 'Demande totale',
  new: 'Nouveau',
  pending: 'en attendant',
  update: 'mettre à jour',
  updated10MinAgo: 'Mis à jour il y a 10 minutes',
  addNewCustomer: 'Ajouter un nouveau client',
  name: 'prénom',
  email: 'Email',
  editMember: 'Modifier le membre',
  scheduleDate: 'Date de programmation',
  title: 'Titre',
  newEmails: 'Nouveaux Emails',
  newEmail: 'nouveau courriel',
  employeePayroll: 'Employee Payroll',
  forMostOfTheUsers: 'Pour la plupart des utilisateurs',
  choosePlan: 'Choisissez Plan',
  mega: 'méga',
  master: 'Maîtriser',
  forDeveloper: 'pour développeur',
  forLargeEnterprises: 'Pour les grandes entreprises',
  composeEmail: 'Écrire un email',
  mailboxes: 'Boîtes aux lettres',
  folders: 'Dossiers',
  settings: 'Paramètres',
  inbox5: 'Boîte de réception (5)',
  draft: 'Brouillon',
  starred: 'Marqué',
  sentMessages: 'Messages envoyés',
  spam: 'Spam',
  delete: 'Effacer',
  work: 'Travail',
  personal: 'Personnel',
  manageFolders: 'Gérer les dossiers',
  contacts: 'Contacts',
  themeOptions: 'Options du thème',
  darkMode: 'Mode sombre',
  collapseSidebar: 'Réduire la barre latérale',
  boxLayout: 'Box Layout',
  rtlLayout: 'Rtl Layout',
  backgroundImage: 'Image de fond',
  sidebarFilters: 'Filtres de barre latérale',
  danger: 'Rose',
  primary: 'Primaire',
  warning: 'Attention',
  success: 'Succès',
  info: 'Violet',
  dailySales: 'Ventes quotidiennes',
  trafficChannel: 'Canal de trafic',
  campaignPerformance: 'Performance de la campagne',
  goToCampaign: 'Aller à la campagne',
  fullScreen: 'Plein écran',
  ecommerce: 'Commerce électronique',
  shop: 'Boutique',
  cart: 'Chariot',
  total: 'Total',
  totalPrice: 'prix total',
  viewCart: 'Voir le panier',
  checkout: 'Check-out',
  apply: 'Appliquer',
  noItemsFound: 'Aucun élément trouvé',
  billingDetails: 'Détails de la facturation',
  placeOrder: 'Passer la commande',
  addNewCard: 'Ajouter une nouvelle carte',
  devicesShare: 'Partage de périphériques',
  customerOverview: 'Aperçu du client',
  reviews: 'Avis',
  weeklySales: 'Ventes hebdomadaires',
  recentOrders: 'Dernières commandes',
  categorySales: 'Catégorie Ventes',
  topSelling: 'Meilleures ventes',
  social: 'Social',
  newPost: 'Nouveau message',
  publish: 'Publier',
  webAnalytics: "analyses d'audience Internet",
  magazine: 'Magazine',
  editUser: "Modifier l'utilisateur",
  addNewUser: 'Ajouter un nouvel utilisateur',
  dark: 'Foncé',
  light: 'Lumière',
  recentUsers: 'Utilisateurs récents',
  activityAroundWorld: 'Activité autour du monde',
  adsIncome: 'Revenus publicitaires',
  recentComments: 'Commentaires récents',
  recentNotifications: 'Notifications récentes',
  messages: 'messages',
  edit: 'modifier',
  topAuthors: 'Top Auteurs',
  marketingCampaign: 'Campagne de marketing',
  articles: 'Des articles',
  websiteTraffic: 'Trafic du site',
  agency: 'Agence',
  recent: 'Récent',
  trending: 'Tendance',
  totalPageViews: 'Nombre total de pages vues',
  impressions: 'Impressions',
  deviceSeparations: 'Séparations de périphériques',
  browserStatics: 'Statistiques du navigateur',
  totalDownloading: 'Téléchargement total',
  notifications: 'Notifications',
  totalBookmarked: 'Total mis en signet',
  itemsDownloads: 'Articles Téléchargements',
  itemsUploaded: 'Articles téléchargés',
  totalAnalytics: 'Total Analytics',
  hotKeywords: 'Mots-clés chauds',
  MaleUsers: 'Utilisateurs masculins',
  serverStatus: 'Statut du serveur',
  purchaseVuely: 'Acheter Vuely',
  letsGetInTouch: 'Permet de se mettre en contact',
  maleUsers: 'Utilisateurs masculins',
  femaleUsers: 'Utilisateurs féminins',
  androidUsers: 'Utilisateurs Android',
  iOSUsers: 'Utilisateurs iOS',
  advance: 'Avance',
  routerAnimation: 'Animation du routeur',
  salesAndEarning: 'Ventes et gains',
  netProfit: 'Bénéfice net',
  totalExpences: 'Dépenses totales',
  photos: 'Photos',
  adCampaignPerfomance: 'Performance de la campagne publicitaire',
  profitShare: 'Une part des bénéfices',
  topSellingTheme: 'Thème le plus vendu',
  newRequest: 'Nouvelle requête',
  followers: 'Suiveurs',
  mailbox: 'Boites aux lettres',
  sent: 'Envoyé',
  birthdays: 'Anniversaires',
  events: 'Événements',
  newsletter: 'Bulletin',
  lastMonth: 'Le mois dernier',
  allTime: 'Tout le temps',
  composeNewEmail: 'Composer un nouveau courriel',
  activity: 'Activité',
  message: 'Message',
  selectTheme: 'Sélectionne un thème',
  continueShopping: 'Continuer vos achats',
  proceedToCheckout: 'Passer à la caisse',
  headerFilters: 'Filtres d"en-tête',
  blog: "Blog",
  signUp: "S'inscrire",
  login: "S'identifier",
  news: "Actualités",
  topHeadlines: "Top titres",
  visitors: "visiteurs",
  subscribers: "Les abonnés",
  twitterFeeds: "Flux Twitter",
  extensions: "Les extensions",
  imageCropper: "Cropper d'image",
  videoPlayer: "Lecteur vidéo",
  dropzone: "Zone de largage",
  baseConfig: "Base Config",
  audioTrackAndPlaysInline: "Piste audio et jeux en ligne",
  hlsLive: "HLS Live",
  forgotPassword: "Mot de passe oublié",
  resetPassword: "réinitialiser le mot de passe",
  backToSignIn: "Retour à la connexion",
  enterYourEmailToSendYouAResetLink: "Entrez votre email pour vous envoyer un lien de réinitialisation",
  pleaseEnterYourPasswordToReset: "Veuillez entrer votre mot de passe pour réinitialiser",
  saas: "SAAS",
  overallTrafficStatus: "État général du trafic",
  projectManagement: "Gestion de projet",
  projectTaskManagement: "Gestion des tâches du projet",
  totalSales: "Ventes totales",
  simple: "Simple",
  transitions: "Transitions",
  tax: "Impôt",
  expenses: "Les dépenses",
  images: "Images",
  ratings: "Évaluations",
  containAndCover: 'Contenir et couvrir',
  height: 'la taille',
  fixedRatio: 'Ratio fixe',
  gradients: 'Les dégradés',
  sizeVariants: 'Variantes de taille',
  newIcons: 'Nouvelles icônes',
  webApplication: 'Application Web',
  person: 'La personne',
  file: 'Fichier',
  comment: 'Commentaire',
  form: 'Forme',
  hardware: 'Matériel',
  directional: 'Directionnel',
  mapAliases: 'Carte (Alias)',
  dateAndTime: 'Date et l "heure"',
  groups: "Groupes",
  buttonGroups: "Groupes de boutons",
  itemGroups: "Groupes d'articles",
  windows: "les fenêtres",
  onBoarding: "À l'embarquement",
  accountCreation: "Création de compte",
  treeview: "Treeview",
  customSelectableIcons: "Icônes sélectionnables personnalisées",
  timelines: "Les délais",
  usage: "Usage",
  scopedSlots: "Scoped Slots",
  asyncItems: "Articles asynchrones",
  smallDots: "Petits points",
  iconDots: "Points d'icône",
  coloredDots: "Points de couleur",
  oppositeSlot: "Slot opposé",
  denseAlert: "Alerte dense",
  fixedTabs: "Onglets fixes",
  rightAlignedTabs: "Onglets alignés à droite",
  content: "Contenu",
  withSearch: "Avec recherche",
  iconsAndText: "Icônes Et Texte",
  grow: "Croître",
  pagination: "Pagination",
  customIcons: "Icônes Personnalisées",
   courses:"Cours",
  coursesList:"Liste des cours",
  courseDetail:"Détail des cours",
  signIn:"Se connecter",
  payment:"Paiement",
  LearnWithYourConvenience:"Apprendre avec votre commodité",
  management:"La gestion",
  design:"Conception",
  development:"Développement",
  top:"Haut",
  new:"Nouveau",
  trending:"Tendances",
  bestseller:"Best-seller",
  addToCart:"Ajouter au panier",
  popularInstructors:"Instructeurs populaires",
  moreCoursesFromJamesColt:"Plus de cours de James Colt",
  whatYoWillLearn:"Ce que vous apprendrez",
  description:"La description",
  aboutInstructor:"A propos de l'instructeur",
  userSignIn:"Connexion utilisateur",
  guestCheckout:"Commander des invités",
  continueAsGuest:"Continuer en tant qu'invité",
  paymentOptions:"Options de paiement",
  offerCode:"Code d'offre",
  enterCardDetails:"Entrer les détails de la carte",
  submit:"Soumettre",
  clear:"Clair",
  makePayment:"Effectuer le paiement",
  usage: "Usage",
  withContent: "Avec contenu",
  customHeight: "Hauteur personnalisée"
}
