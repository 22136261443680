import Vue from 'vue'
import Router from 'vue-router'

//routes
import defaultRoutes from './default';
import horizontalRoutes from './horizontal';
import boxedRoutes from './boxed';
import mini from './mini';
import boxedV2 from './boxed-v2';

// session components
const Login = () => import('@/views/Login')
const SignUpOne = () => import('Views/session/SignUpOne');
const LoginOne = () => import('Views/session/LoginOne');
const ForgotPassword = () => import('Views/session/ForgotPassword');
const ResetPassword = () => import('Views/session/ResetPassword');

Vue.use(Router)

export default new Router({
	mode: 'hash', //history
	routes: [
		defaultRoutes,
		horizontalRoutes,
		boxedRoutes,
		mini,
		boxedV2,
		// {
		// 	name: 'signup',
		// 	path: '/session/sign-up',
		// 	component: SignUpOne,
		// 	meta: {
		// 		title: 'message.signUp',
		// 		breadcrumb: null
		// 	}
		// },
		{
			name: 'login',
			path: '/login',
			component: Login,
			meta: {
				title: 'message.login',
				breadcrumb: null
			}
		},
		// {
		// 	name: 'forgetPassword',
		// 	path: '/session/forgot-password',
		// 	component: ForgotPassword,
		// 	meta: {
		// 		title: 'message.forgotPassword',
		// 		breadcrumb: null
		// 	}
		// },
		// {
		// 	name: 'resetPassword',
		// 	path: '/session/reset-password',
		// 	component: ResetPassword,
		// 	meta: {
		// 		title: 'message.resetPassword',
		// 		breadcrumb: null
		// 	}
		// }
	]
})
