//messages.js
export default {
    general: 'Generale',
    overview: 'Panoramica',
    customizer: 'Customizer',
    features: 'applicazioni',
    components: 'Caratteristiche',
    applications: 'componenti',
    salesAndVisitStats: 'Statistiche di vendita e visite',
    orderAndProjectStats: 'Statistiche ordine e progetti',
    fitnessStats: 'Statistiche di fitness',
    supportAndUsefulWidgets: 'Supporto e widget utili',
    dashboard: 'Cruscotto',
    dashboardOverview: 'Panoramica del cruscotto',
    dashboardv1: 'Dashboard V1',
    dashboardv2: 'Dashboard V2',
    widgets: 'widget',
    horizontalMenu: 'Menu orizzontale',
    pages: 'pagine',
    gallery: 'Galleria',
    pricing: 'Prezzi',
    pricing1: 'Prezzi 1',
    pricing2: 'Prezzi 2',
    blank: 'vuoto',
    session: 'Sessione',
    signUp1: 'Iscriviti 1',
    signUp2: 'Registrati 2',
    login1: 'Login 1',
    login2: 'Login 2',
    lockScreen: 'Blocca schermo',
    uiElements: "Elementi dell'interfaccia utente",
    buttons: 'pulsanti',
    cards: 'Carte',
    checkbox: 'casella di controllo',
    carousel: 'Giostra',
    chips: 'Patatine fritte',
    datepicker: 'Date picker',
    dialog: 'Dialogo',
    grid: 'Griglia',
    input: 'Ingresso',
    list: 'Elenco',
    menu: 'Menu',
    progress: 'Progresso',
    radio: 'Radio',
    select: 'Selezionare',
    slider: 'Slider',
    snackbar: 'Snack bar',
    tabs: 'Tabs',
    toolbar: 'Toolbar',
    tooltip: 'tooltip',
    timepicker: 'TimePicker',
    forms: 'Forme',
    formValidation: 'Convalida del modulo',
    stepper: 'stepper',
    charts: 'Grafici',
    vueChartjs: 'Vue Chartjs',
    vueEcharts: 'Vue Echarts',
    icons: 'Icone',
    themify: 'Themify',
    material: 'Materiale',
    tables: 'tabelle',
    standard: 'Standard',
    slots: 'slot',
    selectable: 'selezionabile',
    searchRow: 'Cerca Riga',
    maps: 'Mappe',
    googleMaps: 'Google Maps',
    leafletMaps: 'Mappe dei volantini',
    jvectorMap: 'Jvector Map',
    inbox: 'Posta in arrivo',
    users: 'utenti',
    userProfile: 'Profilo utente',
    usersList: 'Elenco degli utenti',
    calendar: 'Calendario',
    editor: 'editore',
    quillEditor: 'Editor di quill',
    wYSIWYG: 'WYSIWYG',
    vue2Dragula: 'Vue2 Dragula',
    dragAndDrop: 'Trascinare e rilasciare',
    vueDraggable: 'Vue Draggable',
    draggableResizeable: 'Trascinabile ridimensionabile',
    chat: 'Chiacchierare',
    todo: 'Fare',
    modules: 'moduli',
    user: 'Utente',
    miscellaneous: 'miscellaneo',
    promo: 'Promo',
    connections: 'Connessioni',
    follow: 'Seguire',
    unfollow: 'Smetti',
    basicValidation: 'Convalida di base',
    validationWithSubmitAndclear: 'Convalida con submit & clear',
    submit: 'Sottoscrivi',
    clear: 'Chiaro',
    step1: 'Passo 1',
    step2: 'Passo 2',
    step3: 'Passaggio 3',
    continue: 'Continua',
    cancel: 'Annulla',
    totalEarnings: 'Guadagni complessivi',
    onlineRevenue: 'Entrate online',
    offlineRevenue: 'Entrate offline',
    marketingExpenses: 'Spese di marketing',
    newCustomers: 'nuovi clienti',
    visitAndSalesStatistics: 'Visita e statistiche sulle vendite',
    collapse: 'Crollo',
    reload: 'Ricaricare',
    close: 'Vicino',
    bandwidthUsage: 'Utilizzo della larghezza di banda',
    shoppingCart: 'Carrello della spesa',
    totalDownloads: 'Download totali',
    productSales: 'Le vendite di prodotti',
    projectStatus: 'Stato del progetto',
    averageSteps: 'Passi medi',
    todaysDistance: 'La distanza di oggi',
    todaysStep: 'Il passo di oggi',
    todaysGoal: 'Obiettivo di oggi',
    calories: 'calorie',
    weeklySummary: 'Sommario settimanale',
    supportTickets: 'Supporta i biglietti',
    todoList: 'Lista di cose da fare',
    newsletterCampaign: 'Campagna Newsletter',
    currentVisitors: 'Visitatori attuali',
    newMembers: 'Nuovi membri',
    addNewBlog: 'Aggiungi nuovo blog',
    add: 'Inserisci',
    logOut: 'Disconnettersi',
    totalAppMemory: "Memoria dell'app totale",
    totalMemoryUsed: 'Memoria totale utilizzata',
    unreadMail: '12 messaggi non letti',
    feedback: 'Risposta',
    flatButton: 'Bottone piatto',
    raisedButton: 'Pulsante alzato',
    gradientButton: 'Pulsante sfumatura',
    buttonDropdownVariants: 'Varianti del menu a discesa',
    buttonToggle: 'Pulsante',
    icon: 'Icona',
    normal: 'Normale',
    disabled: 'Disabilitato',
    floating: 'Galleggiante',
    loaders: 'caricatori',
    sizing: 'dimensionamento',
    outline: 'Contorno',
    round: 'Il giro',
    block: 'Bloccare',
    search: 'Ricerca',
    checkboxesBoolean: 'Caselle di controllo Booleano',
    checkboxesArray: 'Array di caselle di controllo',
    checkboxesStates: 'Caselle di controllo - Stati',
    checkboxesColors: 'Caselle di controllo - Colori',
    switches: 'Interruttori',
    default: 'Predefinito',
    customTransition: 'Transizione personalizzata',
    customDelimiter: 'Delimitatore personalizzato',
    hideControls: 'Nascondi i controlli',
    hideDelimiters: 'Nascondi delimitatori',
    colored: 'Colorato',
    label: 'Etichetta',
    closeable: 'closeable',
    datePickerHorizontal: 'Raccoglitore di data orizzontale',
    datePickerVertical: 'Selezione data verticale',
    datePickersInDialogAndMenu: 'Raccoglitori di date: nella finestra di dialogo e nel menu',
    datePickersWithModal: 'Raccoglitori di data con modale',
    datePickersFormattingDate: 'Raccoglitori di date - Data di formattazione',
    simpleDialogs: 'Dialoghi semplici',
    withoutActivator: 'Senza attivatore',
    scrollable: 'scorrevole',
    listOfAllMaterialIcons: 'Elenco di tutte le icone dei materiali',
    arrowsAndDirectionsIcons: 'ICONE DI FRECCE E DIREZIONE',
    webAppIcons: 'ICONE APP WEB',
    CONTROLICONS: 'ICONE DI CONTROLLO',
    TEXTEDITOR: 'EDITOR DI TESTO',
    LAYOUTICONS: 'ICONE DI LAYOUT',
    BRANDICONS: 'ICONE DEL BRAND',
    newClients: 'Nuovi clienti',
    recurringClients: 'Clienti ricorrenti',
    bounceRate: 'Frequenza di rimbalzo',
    pageViews: 'Visualizzazioni di pagina',
    usefulWidgets: 'Widget utili',
    sale: 'saldi',
    sales: 'I saldi',
    invoices: 'Fatture',
    referrals: 'Referenti',
    serverLoad: 'Carico del server',
    recentSale: 'Vendita recente',
    supportRequest: 'Richiesta di supporto',
    productSale: 'Vendita del prodotto',
    viewAll: 'GUARDA TUTTO',
    barChart: 'Grafico a barre',
    polarAreaChart: "Grafico dell'area polare",
    lineChart: 'Grafico a linee',
    radarChart: 'Grafico radar',
    doughnutChart: 'Grafico ad anello',
    bubbleChart: 'Bubble Chart',
    gradientLineChart: 'Grafico a linee sfumate',
    pieChart: 'Grafico a torta',
    funnelChart: 'Grafico a imbuto',
    loginNow: 'Accedi ora',
    createAccount: 'Creare un profilo',
    termsOfService: 'Termini di servizio',
    bySigningUpYouAgreeTo: 'Iscrivendoti accetti',
    loginToAdmin: "Accedi all'amministratore",
    enterUsernameAndPasswordToAccessControlPanelOf: 'Inserire nome utente e password per accedere al pannello di controllo di',
    havingAnAccount: 'Avere un account?',
    quickLinks: 'Link veloci',
    plans: 'Piani',
    chooseThePlanThatWorksForYou: 'Scegli il piano che funziona per te.',
    monthly: 'Mensile',
    yearlyGet2MonthExtra: 'Annuale (ricevi 2 mesi extra)',
    basic: 'Di base',
    pro: 'professionista',
    advanced: 'Avanzate',
    basicFree: 'Base (gratuito)',
    startToBasic: 'Inizia per base',
    upgradeToPro: 'Aggiornamento a Pro.',
    upgradeToAdvance: 'Aggiornamento in anticipo',
    comparePlans: 'Confronta i piani',
    frequentlyAskedQuestions: 'Domande frequenti',
    defaultInput: 'Ingresso predefinito',
    activator: 'Activator',
    hover: 'librarsi',
    menus: 'menu',
    indeterminate: 'Indeterminato',
    sizeAndWidth: 'Dimensioni e larghezza',
    rotate: 'Ruotare',
    determinate: 'Determinato',
    buffer: 'Buffer',
    radiosDefault: 'Predefinito radio',
    radiosDirectionRow: 'Riga di direzione delle radio',
    radiosDirectionColumn: 'Colonna di direzione radio',
    radiosColors: 'Radio - Colori',
    switchesColors: 'Interruttori - Colori',
    continuous: 'Continuo',
    discrete: 'Discreto',
    customColors: 'Colori personalizzati',
    editableNumericValue: 'Valore numerico modificabile',
    contextualSnackbar: 'Snack bar contestuale',
    showSnackbar: 'Mostra snack',
    centeredTabs: 'Schede centrate',
    toolbarTabs: 'Schede della barra degli strumenti',
    theDefaultColorToolbar: 'La barra dei colori predefinita',
    appBar: 'App Bar',
    appBarWithExtension: 'App Bar con estensione',
    visibility: 'Visibilità',
    top: 'Superiore',
    right: 'Destra',
    bottom: 'Parte inferiore',
    left: 'Sinistra',
    toggle: 'Toggle',
    timePicker: 'Time Picker',
    timePickerInDialogAndMenu: 'Time Picker - In finestra di dialogo e menu',
    itemsAndHeaders: 'Articoli e intestazioni',
    selectableRows: 'Righe selezionabili',
    headerCell: 'Intestazione',
    expand: 'Espandere',
    recentChat: 'Chat recente',
    previousChats: 'Chat precedenti',
    addNewItems: 'Aggiungi nuovi articoli',
    addToDo: 'Aggiungi a fare',
    yes: 'sì',
    ok: 'Ok',
    activeUsers: 'Utenti attivi',
    addNew: 'Aggiungere nuova',
    readMore: 'Leggi di più',
    assignNow: 'Assegna ora',
    totalRequest: 'Richiesta totale',
    new: 'Nuovo',
    pending: 'in attesa di',
    update: 'Aggiornare',
    updated10MinAgo: 'Aggiornato 10 minuti fa',
    addNewCustomer: 'Aggiungi nuovo cliente',
    name: 'Nome',
    email: 'E-mail',
    editMember: 'Modifica membro',
    scheduleDate: 'Data del programma',
    title: 'Titolo',
    newEmails: 'Nuove email',
    newEmail: 'nuova email',
    employeePayroll: 'Paghe dei dipendenti',
    forMostOfTheUsers: 'Per la maggior parte degli utenti',
    choosePlan: 'Scegli il piano',
    mega: 'Mega',
    master: 'Maestro',
    forDeveloper: 'Per lo sviluppatore',
    forLargeEnterprises: 'Per le grandi imprese',
    composeEmail: 'Componi email',
    mailboxes: 'Caselle di posta',
    folders: 'Cartelle',
    inbox5: 'Posta in arrivo (5)',
    draft: 'Bozza',
    starred: 'Ha recitato',
    sentMessages: 'Messaggi inviati',
    spam: 'Spam',
    delete: 'Elimina',
    work: 'Lavoro',
    personal: 'Personale',
    manageFolders: 'Gestisci cartelle',
    contacts: 'Contatti',
    settings: 'impostazioni',
    themeOptions: 'Opzioni del tema',
    darkMode: 'Modalità oscura',
    collapseSidebar: 'Collapse Sidebar',
    boxLayout: 'Layout della scatola',
    rtlLayout: 'Rtl Layout',
    backgroundImage: 'Immagine di sfondo',
    sidebarFilters: 'Filtri della barra laterale',
    danger: 'Pericolo',
    primary: 'Primario',
    warning: 'avvertimento',
    success: 'Successo',
    info: 'Informazioni',
    dailySales: 'Vendite giornaliere',
    trafficChannel: 'Canale del traffico',
    campaignPerformance: 'Rendimento della campagna',
    goToCampaign: 'Vai alla campagna',
    fullScreen: 'A schermo intero',
    ecommerce: 'E-Commerce',
    shop: 'Negozio',
    cart: 'Carrello',
    total: 'Totale',
    totalPrice: 'Prezzo totale',
    viewCart: 'Visualizza il carrello',
    checkout: 'Check-out',
    apply: 'Applicare',
    noItemsFound: 'Nessun articolo trovato',
    billingDetails: 'Dettagli di fatturazione',
    placeOrder: 'Invia ordine',
    addNewCard: 'Aggiungi nuova carta',
    devicesShare: 'Dispositivi Condividi',
    customerOverview: 'Panoramica del cliente',
    reviews: 'Recensioni',
    weeklySales: 'Vendite settimanali',
    recentOrders: 'Ordini Recenti',
    categorySales: 'Vendite di categoria',
    webAnalytics: 'Web Analytics',
    topSelling: 'I più venduti',
    social: 'Sociale',
    newPost: 'Nuovo post',
    publish: 'Pubblicare',
    magazine: 'Rivista',
    editUser: 'Modifica utente',
    addNewUser: 'Aggiungi nuovo utente',
    dark: 'Buio',
    light: 'Leggero',
    recentUsers: 'Utenti recenti',
    activityAroundWorld: 'Attività nel mondo',
    adsIncome: 'Redditi pubblicitari',
    recentComments: 'Commenti recenti',
    recentNotifications: 'Notifiche recenti',
    messages: 'messaggi',
    edit: 'modificare',
    topAuthors: 'Autori principali',
    marketingCampaign: 'Campagna di marketing',
    articles: 'articoli',
    websiteTraffic: 'Traffico del sito web',
    agency: 'Agenzia',
    recent: 'Recente',
    trending: 'Trending',
    totalPageViews: 'Visualizzazioni totali della pagina',
    impressions: 'impressioni',
    deviceSeparations: 'Separazioni dispositivo',
    browserStatics: 'Statistica del browser',
    totalDownloading: 'Download totale',
    notifications: 'notifiche',
    totalBookmarked: 'Totale segnalibro',
    itemsDownloads: 'Download di articoli',
    itemsUploaded: 'Articoli caricati',
    totalAnalytics: 'Total Analytics',
    hotKeywords: 'Parole chiave bollenti',
    MaleUsers: 'Utenti Maschi',
    serverStatus: 'Stato del server',
    purchaseVuely: 'Acquista Vuely',
    letsGetInTouch: 'Tienilo in contatto',
    maleUsers: 'Utenti Maschi',
    femaleUsers: 'Utenti femminili',
    androidUsers: 'Utenti Android',
    iOSUsers: 'Utenti iOS',
    advance: 'Avanzare',
    routerAnimation: 'Animazione del router',
    salesAndEarning: 'Vendite e guadagni',
    netProfit: 'Profitto netto',
    totalExpences: 'Totale spese',
    photos: 'Fotografie',
    adCampaignPerfomance: 'Perfomance della campagna pubblicitaria',
    profitShare: 'Condividere il profitto',
    topSellingTheme: 'Tema più venduto',
    newRequest: 'Nuova richiesta',
    followers: 'Seguaci',
    mailbox: 'Cassetta postale',
    sent: 'Inviato',
    birthdays: 'Compleanni',
    events: 'eventi',
    newsletter: 'notiziario',
    lastMonth: 'Lo scorso mese',
    allTime: 'Sempre',
    composeNewEmail: 'Scrivi nuova email',
    activity: 'Attività',
    message: 'Messaggio',
    selectTheme: 'Seleziona il tema',
    continueShopping: 'Continua a fare acquisti',
    proceedToCheckout: 'Procedere al checkout',
    headerFilters: 'Filtri di intestazione',
    blog: 'blog',
    signUp: 'Iscriviti',
    login: 'Accesso',
    news: 'notizia',
    topHeadlines: 'Top titoli',
    visitors: 'I visitatori',
    subscribers: 'Iscritti',
    twitterFeeds: 'Feed Twitter',
    extensions: 'estensioni',
    imageCropper: 'Cropper di immagini',
    videoPlayer: 'Lettore video',
    dropzone: 'Zona di rilascio',
    baseConfig: 'Base Config',
    audioTrackAndPlaysInline: 'Audio Track e Plays Inline',
    hlsLive: 'HLS Live',
    forgotPassword: 'Ha dimenticato la password',
    resetPassword: 'Resetta la password',
    backToSignIn: 'Torna per accedere',
    enterYourEmailToSendYouAResetLink: 'Inserisci la tua email per inviarti un link di ripristino',
    pleaseEnterYourPasswordToReset: 'Si prega di inserire la password per ripristinare',
    saas: 'SAAS',
    overallTrafficStatus: 'Stato del traffico complessivo',
    projectManagement: 'Gestione di progetto',
    projectTaskManagement: 'Gestione delle attività del progetto',
    totalSales: 'Vendite totali',
    simple: 'Semplice',
    transitions: 'transizioni',
    tax: 'Imposta',
    expenses: 'Spese',
    images: 'immagini',
    ratings: 'Giudizi',
    containAndCover: 'Contiene e copre',
    height: 'Altezza',
    fixedRatio: 'Rapporto fisso',
    gradients: 'Sfumature',
    sizeVariants: 'Varianti di taglia',
    newIcons: 'Nuove icone',
    webApplication: 'Applicazione web',
    person: 'Persona',
    file: 'File',
    comment: 'Commento',
    form: 'Modulo',
    hardware: 'Hardware',
    directional: 'Direzionale',
    mapAliases: 'Mappa (pseudonimi)',
    dateAndTime: 'Data e ora',
    groups: "gruppi",
    buttonGroups: "Gruppi di pulsanti",
    itemGroups: "Gruppi di articoli",
    windows: "finestre",
    onBoarding: "A bordo",
    accountCreation: "Creazione account",
    treeview: "Visualizzazione ad albero",
    customSelectableIcons: "Icone selezionabili personalizzate",
    timelines: "Linea del tempo",
    usage: "uso",
    scopedSlots: "Scanalature scopate",
    asyncItems: "Articoli asincroni",
    smallDots: "Piccoli punti",
    iconDots: "Punti icona",
    coloredDots: "Puntini colorati",
    oppositeSlot: "Slot opposto",
    denseAlert: "Avviso denso",
    fixedTabs: "Tab Fisso",
    rightAlignedTabs: "Schede allineate a destra",
    content: "Soddisfare",
    withSearch: "Con la ricerca",
    iconsAndText: "Icone e testo",
    grow: "Crescere",
    pagination: "paginatura",
    customIcons: "Icone personalizzate",
    courses:"corsi",
    coursesList:"Elenco dei corsi",
    courseDetail:"Dettaglio dei corsi",
    signIn:"Registrati",
    payment:"Pagamento",
    LearnWithYourConvenience:"Impara con la tua convenienza",
    management:"Gestione",
    design:"Design",
    development:"Sviluppo",
    top:"Superiore",
    new:"Nuovo",
    trending:"Trending",
    bestseller:"Il più venduto",
    addToCart:"Aggiungi al carrello",
    popularInstructors:"Istruttori popolari",
    moreCoursesFromJamesColt:"Più corsi da James Colt",
    whatYoWillLearn:"Cosa imparerai",
    description:"Descrizione",
    aboutInstructor:"Informazioni sull'istruttore",
    userSignIn:"Accesso utente",
    guestCheckout:"Guest Checkout",
    continueAsGuest:"Continua come ospite",
    paymentOptions:"Opzioni di pagamento",
    offerCode:"Codice offerta",
    enterCardDetails:"Inserisci i dettagli della carta",
    submit:"Sottoscrivi",
    clear:"Chiaro",
    makePayment:"Fare un pagamento",
    usage: "uso",
    withContent: "Con contenuto",
    customHeight: "Altezza personalizzata"
}
