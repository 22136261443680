<template>
  <v-app
    :dark="darkMode"
    id="inspire"
    :class="[{ 
      'box-layout': boxLayout, 
      'collapse-sidebar': collapseSidebar, 
      'rtl-layout': rtlLayout
    }]"
    > 
    	<router-view :auth="auth" v-resize="onResize"  :authenticated="authenticated"></router-view>
      <notifications 
        group="loggedIn" 
        position="top right"
        animation-type="velocity"
      />
  </v-app>
</template>

<script>
import { mapGetters } from "vuex";

import AuthService from "./auth/AuthService";

const auth = new AuthService();

const { login, logout, authenticated, authNotifier } = auth;

export default {
  data() {
    authNotifier.on("authChange", authState => {
      this.authenticated = authState.authenticated;
    });
    return {
      windowSize: {
        x: 0,
        y: 0
      },
      auth,
      authenticated,
      animation: {
        enter: {
          opacity: [1, 0],
          translateX: [0, -300],
          scale: [1, 0.2]
        },
        leave: {
          opacity: 0,
          height: 0
        }
     }
    };
  },
  mounted() {
    if (
      this.selectedLocale.locale === "he" ||
      this.selectedLocale.locale === "ar"
    ) {
      this.$store.dispatch("rtlLayout");
    }
    this.onResize()
  },
  computed: {
    ...mapGetters([
      "darkMode",
      "collapseSidebar",
      "boxLayout",
      "rtlLayout",
      "selectedLocale"
    ])
  },
  methods: {
    onResize () {
      // this.windowSize = { x: window.innerWidth*90/100, y: window.innerHeight*90/100}
      this.windowSize = { x: window.innerWidth*100/100, y: window.innerHeight*100/100}
    }
  }
};
</script>