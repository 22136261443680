export const authHeader = function ()
{	

//    / alert(localStorage.access_token)
    const accessToken= localStorage.access_token
    const headers = {'headers':{
    'Accept': 'application/json',
    'Authorization': 'Bearer '+ accessToken
    }}
    
    return headers
}

export const dateDisplayMask = function(date)
{
    var date = new Date(date)
    date = "" + (date.getDate() > 9 ? '' : '0') + date.getDate() + '/'+  ((date.getMonth() + 1) > 9 ? '' : '0') + (date.getMonth() + 1) + '/' + date.getFullYear()

    return date
}

export const setCurrentDate = function()
{
    var today = new Date();
    var dd = today.getDate();
    var mm = today.getMonth() + 1; //January is 0!

    var yyyy = today.getFullYear();
    if (dd < 10) {
    dd = '0' + dd;
    } 
    if (mm < 10) {
    mm = '0' + mm;
    } 
    var today = yyyy + "-" + mm + "-" + dd; 
    return today;
}


