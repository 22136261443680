//messages.js
export default {
  general: 'általános',
  overview: 'Áttekintés',
  customizer: 'Testreszabás',
  applications: 'Alkalmazások',
  features: 'Szolgáltatások',
  components: 'komponensek',
  salesAndVisitStats: 'Értékesítési és látogatási statisztika',
  orderAndProjectStats: 'Rendelés és projektek statisztikái',
  fitnessStats: 'Fitness statisztika',
  supportAndUsefulWidgets: 'Támogatás és hasznos widgetek',
  dashboard: 'irányítópult',
  dashboardOverview: 'Irányítópult áttekintése',
  dashboardv1: 'Vezérlőpult V1',
  dashboardv2: 'Vezérlőpult V2',
  widgets: 'Widgets',
  horizontalMenu: 'Vízszintes menü',
  pages: 'Oldalak',
  gallery: 'Galéria',
  pricing: 'Árazás',
  pricing1: 'Árképzés 1',
  pricing2: 'Árképzés 2',
  blank: 'üres',
  session: 'Ülés',
  signUp1: 'Regisztrálj 1',
  signUp2: 'Regisztráció 2',
  login1: 'Bejelentkezés 1',
  login2: 'Bejelentkezés 2',
  lockScreen: 'Zárolás képernyő',
  uiElements: 'UI elemek',
  buttons: 'Gombok',
  cards: 'kártyák',
  checkbox: 'jelölőnégyzetet',
  carousel: 'körhinta',
  chips: 'Játékpénz',
  datepicker: 'Dátumválasztó',
  dialog: 'Párbeszéd',
  grid: 'Rács',
  input: 'bemenet',
  list: 'Lista',
  menu: 'Menü',
  progress: 'előrehaladás',
  radio: 'Rádió',
  select: 'választ',
  slider: 'Slider',
  snackbar: 'snackbár',
  tabs: 'Tabok',
  toolbar: 'Eszköztár',
  tooltip: 'Tooltip',
  timepicker: 'Timepicker',
  forms: 'Formák',
  formValidation: 'Form validálás',
  stepper: 'Stepper',
  charts: 'diagramok',
  vueChartjs: 'Vue Charts',
  vueEcharts: 'Vue Echarts',
  icons: 'Ikonok',
  themify: 'Themify',
  material: 'Anyag',
  tables: 'táblázatok',
  standard: 'Alapértelmezett',
  slots: 'slots',
  selectable: 'Választható',
  searchRow: 'Keresési sor',
  maps: 'Térképek',
  googleMaps: 'Google Térkép',
  leafletMaps: 'Leaflet Térképek',
  jvectorMap: 'Jvector térkép',
  inbox: 'Beérkező levelek',
  users: 'Felhasználók',
  userProfile: 'Felhasználói profil',
  usersList: 'Felhasználók listája',
  calendar: 'Naptár',
  editor: 'Szerkesztő',
  quillEditor: 'Quill szerkesztő',
  wYSIWYG: 'WYSIWYG',
  vue2Dragula: 'Vue2 Dragula',
  dragAndDrop: 'Drag And Drop',
  vueDraggable: 'Vue Draggable',
  draggableResizeable: 'Húzható átméretezhető',
  chat: 'Csevegés',
  todo: 'Csinálni',
  modules: 'modulok',
  user: 'használó',
  miscellaneous: 'Vegyes',
  promo: 'Promo',
  connections: 'kapcsolatok',
  follow: 'Kövesse',
  unfollow: 'unfollow',
  basicValidation: 'Alapvető érvényesítés',
  validationWithSubmitAndclear: 'Érvényesítés benyújtása és egyértelmű',
  submit: 'beküldése',
  clear: 'Egyértelmű',
  step1: '1. lépés',
  step2: '2. lépés',
  step3: '3. lépés',
  continue: 'Folytatni',
  cancel: 'Megszünteti',
  totalEarnings: 'Összes bevétel',
  onlineRevenue: 'Online bevételek',
  offlineRevenue: 'Offline bevétel',
  marketingExpenses: 'Marketing költségek',
  newCustomers: 'új vásárlók',
  visitAndSalesStatistics: 'Látogatás és értékesítési statisztikák',
  collapse: 'Összeomlás',
  reload: 'Reload',
  close: 'Bezárás',
  bandwidthUsage: 'Sávszélesség használata',
  shoppingCart: 'Bevásárlókocsi',
  totalDownloads: 'Összes letöltés',
  productSales: 'Termékértékesítés',
  projectStatus: 'Projekt állapota',
  averageSteps: 'Átlagos lépések',
  todaysDistance: "A mai távolság",
  todaysStep: "A mai lépés",
  todaysGoal: "A mai cél",
  calories: 'Kalória',
  weeklySummary: 'Heti összefoglaló',
  supportTickets: 'Jegyek támogatása',
  todoList: 'Feladatlista',
  newsletterCampaign: 'Hírlevél kampány',
  currentVisitors: 'Aktuális látogatók',
  newMembers: 'Új tagok',
  addNewBlog: 'Új blog hozzáadása',
  add: 'hozzáad',
  logOut: 'Kijelentkezés',
  totalAppMemory: 'Teljes App memória',
  totalMemoryUsed: 'Összes használt memória',
  unreadMail: '12 Olvasatlan Mail',
  feedback: 'Visszajelzés',
  flatButton: 'lapos gomb',
  raisedButton: 'felemelt gomb',
  gradientButton: 'Gradiens gomb',
  buttonDropdownVariants: 'Gomb legördülő változatok',
  buttonToggle: 'Gomb váltás',
  icon: 'ikon',
  normal: 'Normál',
  disabled: 'Letiltva',
  floating: 'lebegő',
  loaders: 'rakodók',
  sizing: 'Méretezés',
  outline: 'Vázlat',
  round: 'körbe',
  block: 'Blokk',
  search: 'Keresés',
  checkboxesBoolean: 'A jelölőnégyzetek logikai értékűek',
  checkboxesArray: 'Checkboxes Array',
  checkboxesStates: 'Jelölőnégyzetek - Államok',
  checkboxesColors: 'Jelölőnégyzékek - Színek',
  switches: 'kapcsolók',
  default: 'Alapértelmezett',
  customTransition: 'Egyéni átmenet',
  customDelimiter: 'Egyéni határoló',
  hideControls: 'Irányítás elrejtése',
  hideDelimiters: 'Elrejtők elrejtése',
  colored: 'színes',
  label: 'Címke',
  closeable: 'Zárható',
  datePickerHorizontal: 'Dátumválasztó vízszintesen',
  datePickerVertical: 'Dátumválasztó függőleges',
  datePickersInDialogAndMenu: 'Dátumválasztók - párbeszédablakban és menüben',
  datePickersWithModal: 'Dátumválasztók a Modal segítségével',
  datePickersFormattingDate: 'Dátumválasztók - formázási dátum',
  simpleDialogs: 'Egyszerű párbeszédek',
  withoutActivator: 'Aktivátor nélkül',
  scrollable: 'görgethető',
  listOfAllMaterialIcons: 'Az összes anyagi ikon',
  arrowsAndDirectionsIcons: 'REDOZÁSI ÉS IRÁNYADÓ IKONOK',
  webAppIcons: 'WEB APP ICONS',
  CONTROLICONS: 'VEZÉRLÉSI ICONS',
  TEXTEDITOR: 'SZÖVEG SZERKESZTŐ',
  LAYOUTICONS: 'LOVÁCIÓS ICONS',
  BRANDICONS: 'BRAND ICONS',
  newClients: 'Új ügyfelek',
  recurringClients: 'Ismétlődő ügyfelek',
  bounceRate: 'Visszafordulási arány',
  pageViews: 'Oldalnézetek',
  usefulWidgets: 'Hasznos widgetek',
  sale: 'Eladás',
  sales: 'Sales',
  invoices: 'számlák',
  referrals: 'Ajánlók',
  serverLoad: 'Szerver betöltése',
  recentSale: 'Legutóbbi eladás',
  supportRequest: 'Támogatási kérelem',
  productSale: 'Termékkínálat',
  viewAll: 'ÖSSZES MEGTEKINTÉSE',
  barChart: 'Oszlopdiagram',
  polarAreaChart: 'Polar terület diagram',
  lineChart: 'Vonalrajz',
  radarChart: 'Radar diagram',
  doughnutChart: 'Donut Chart',
  bubbleChart: 'Bubble Chart',
  gradientLineChart: 'Gradiens vonaldiagram',
  pieChart: 'Kördiagram',
  funnelChart: 'Tölcsér-diagram',
  loginNow: 'Jelentkezzen be most',
  createAccount: 'Fiók létrehozása',
  termsOfService: 'Szolgáltatás feltételei',
  bySigningUpYouAgreeTo: 'Feliratkozással beleegyezel',
  loginToAdmin: 'Bejelentkezni az adminisztrátorhoz ',
  enterUsernameAndPasswordToAccessControlPanelOf: 'Adja meg felhasználónevét és jelszavát a vezérlőpanel eléréséhez',
  havingAnAccount: 'Van fiókod?',
  quickLinks: 'Gyors linkek',
  plans: 'tervek',
  chooseThePlanThatWorksForYou: 'Válassza ki az Ön számára megfelelő tervet.',
  monthly: 'Havi',
  yearlyGet2MonthExtra: 'Évente (2 hónapos extra)',
  basic: 'Alapvető',
  pro: 'Pro',
  advanced: 'Fejlett',
  basicFree: 'Alapvető (ingyenes)',
  startToBasic: 'Indítsa el az alapot',
  upgradeToPro: 'Frissítés Pro verzióra.',
  upgradeToAdvance: 'Frissítés előre',
  comparePlans: 'Tervek összehasonlítása',
  frequentlyAskedQuestions: 'Gyakran Ismételt Kérdések',
  defaultInput: 'Alapértelmezett bevitel',
  activator: 'Activator',
  hover: 'Lebeg',
  menus: 'menük',
  indeterminate: 'Határozatlan',
  sizeAndWidth: 'Méret és szélesség',
  rotate: 'Forog',
  determinate: 'Meghatározó',
  buffer: 'ütköző',
  radiosDefault: 'Rádiók Alapértelmezett',
  radiosDirectionRow: 'Rádiók irányvonal',
  radiosDirectionColumn: 'Rádiók irányú oszlop',
  radiosColors: 'Rádiók - színek',
  switchesColors: 'Kapcsolók - színek',
  continuous: 'Folyamatos',
  discrete: 'Diszkrét',
  customColors: 'Egyéni színek',
  editableNumericValue: 'Szerkeszthető számérték',
  contextualSnackbar: 'Kontextusos snackbar',
  showSnackbar: 'A Snackbar megjelenítése',
  centeredTabs: 'Központosított lapok',
  toolbarTabs: 'Eszköztár lapok',
  theDefaultColorToolbar: 'Az Alapértelmezett színes eszköztár',
  appBar: 'App Bar',
  appBarWithExtension: 'Alkalmazásbár kiterjesztéssel',
  visibility: 'Láthatóság',
  top: 'felső',
  right: 'Jobb',
  bottom: 'Alsó',
  left: 'Balra',
  toggle: 'pecek',
  timePicker: 'Időmérő',
  timePickerInDialogAndMenu: 'Időmérő - a párbeszédablakban és a menüben',
  itemsAndHeaders: 'Elemek és fejlécek',
  selectableRows: 'Választható sorok',
  headerCell: 'Fejléccell',
  expand: 'Kiterjed',
  recentChat: 'Legutóbbi csevegés',
  previousChats: 'Korábbi beszélgetések',
  addNewItems: 'Új elemek hozzáadása',
  addToDo: 'Add To Do',
  yes: 'Igen',
  ok: 'Rendben',
  activeUsers: 'Aktív felhasználók',
  addNew: 'Új hozzáadása',
  readMore: 'Olvass tovább',
  assignNow: 'Hozzárendelés most',
  totalRequest: 'Teljes kérelem',
  new: 'Új',
  pending: 'Függőben levő',
  update: 'frissítés',
  updated10MinAgo: 'Frissítve 10 perccel ezelőtt',
  addNewCustomer: 'Új ügyfél hozzáadása',
  name: 'Név',
  email: 'Email',
  editMember: 'Tag szerkesztése',
  scheduleDate: 'Dátum ütemezése',
  title: 'Cím',
  newEmails: 'Új e-mailek',
  newEmail: 'új Email',
  employeePayroll: 'Munkavállalói bérszámfejtés',
  forMostOfTheUsers: 'A legtöbb felhasználó számára',
  choosePlan: 'Válassza ki a Tervet',
  mega: 'Mega',
  master: 'Fő',
  forDeveloper: 'A fejlesztő számára',
  forLargeEnterprises: 'Nagyvállalatok számára',
  composeEmail: 'E-mail írása',
  mailboxes: 'postaládák',
  folders: 'mappák',
  inbox5: 'Bejövő üzenetek (5)',
  draft: 'vázlat',
  starred: 'Csillagos',
  sentMessages: 'Elküldött üzenetek',
  spam: 'Levélszemét',
  delete: 'Töröl',
  work: 'Munka',
  personal: 'Személyes',
  manageFolders: 'Mappák kezelése',
  contacts: 'Kapcsolatok',
  settings: 'Beállítások',
  themeOptions: 'téma beállítások',
  darkMode: 'Sötét mód',
  collapseSidebar: 'Az Oldalsáv bezárása',
  boxLayout: 'Doboz elrendezés',
  rtlLayout: 'Rtl elrendezés',
  backgroundImage: 'Háttérkép',
  sidebarFilters: 'Sidebar szűrők',
  danger: 'Veszély',
  primary: 'Elsődleges',
  warning: 'Figyelem',
  success: 'Siker',
  info: 'Info',
  dailySales: 'Napi értékesítés',
  trafficChannel: 'Forgalmi csatorna',
  campaignPerformance: 'Kampány teljesítmény',
  goToCampaign: 'Menjen a kampányba',
  fullScreen: 'Teljes képernyő',
  ecommerce: 'E-Commerce',
  shop: 'Üzlet',
  cart: 'kocsi',
  total: 'Teljes',
  totalPrice: 'Teljes ár',
  viewCart: 'Kosár megtekintése',
  checkout: 'kijelentkezés',
  apply: 'Alkalmaz',
  noItemsFound: 'Nincs találat',
  billingDetails: 'Számlázási adatok',
  placeOrder: 'Rendelés',
  addNewCard: 'Új kártya hozzáadása',
  devicesShare: 'Eszközök Megosztás',
  customerOverview: 'Ügyfél áttekintése',
  reviews: 'Vélemények',
  weeklySales: 'Heti eladások',
  recentOrders: 'legutóbbi rendelések',
  categorySales: 'Kategória Értékesítés',
  webAnalytics: 'Web Analytics',
  topSelling: 'Legkelendőbb',
  social: 'Társadalmi',
  newPost: 'Új hozzászólás',
  publish: 'közzétesz',
  magazine: 'Magazin',
  editUser: 'Felhasználó szerkesztése',
  addNewUser: 'Új felhasználó hozzáadása',
  dark: 'Sötét',
  light: 'Fény',
  recentUsers: 'Legutóbbi felhasználók',
  activityAroundWorld: 'Tevékenység a világ körül',
  adsIncome: 'Hirdetési jövedelem',
  recentComments: 'legutóbbi hozzászólások',
  recentNotifications: 'Legutóbbi értesítések',
  messages: 'üzenetek',
  edit: 'szerkesztése',
  topAuthors: 'Top Szerzők',
  marketingCampaign: 'Marketingkampány',
  articles: 'Cikkek',
  websiteTraffic: 'Webhelyforgalom',
  agency: 'Ügynökség',
  recent: 'Friss',
  trending: 'Felkapott',
  totalPageViews: 'Összes oldalnézet',
  impressions: 'megjelenítések',
  deviceSeparations: 'Eszközeltávolítások',
  browserStatics: 'Böngésző statisztikái',
  totalDownloading: 'Teljes letöltés',
  notifications: 'értesítések',
  totalBookmarked: 'Összesen fel van sorolva',
  itemsDownloads: 'Elemek Letöltések',
  itemsUploaded: 'Feltöltött elemek',
  totalAnalytics: 'Teljes Analytics',
  hotKeywords: 'Forró kulcsszavak',
  MaleUsers: 'Férfi felhasználók',
  serverStatus: 'Szerver állapota',
  purchaseVuely: 'Vásároljon Vuely-t',
  letsGetInTouch: 'Legyen kapcsolatba',
  maleUsers: 'Férfi felhasználók',
  femaleUsers: 'Női felhasználók',
  androidUsers: 'Android felhasználók',
  iOSUsers: 'iOS felhasználók',
  advance: 'Előleg',
  routerAnimation: 'Router animáció',
  salesAndEarning: 'Értékesítés és nyereség',
  netProfit: 'Nettó nyereség',
  totalExpences: 'Összes költség',
  photos: 'fotók',
  adCampaignPerfomance: 'Hirdetési kampány előfeltétele',
  profitShare: 'Nyereségrészesedés',
  topSellingTheme: 'Top Selling Theme',
  newRequest: 'Új kérés',
  followers: 'Követő',
  mailbox: 'Postafiók',
  sent: 'Küldött',
  birthdays: 'Születésnap',
  events: 'Események',
  newsletter: 'Hírlevél',
  lastMonth: 'Múlt hónap',
  allTime: 'Mindig',
  composeNewEmail: 'Új e-mail írása',
  activity: 'Tevékenység',
  message: 'Üzenet',
  selectTheme: 'Válassz témát',
  continueShopping: 'Folytatni a vásárlást',
  proceedToCheckout: 'Tovább a kijelentkezéshez',
  headerFilters: 'Fejléc szűrők',
  blog: "blog",
  signUp: "Regisztrálj",
  login: "Belépés",
  news: "hírek",
  topHeadlines: "Legnépszerűbb hírek",
  visitors: "Látogatók",
  subscribers: "előfizetőknek",
  twitterFeeds: "Twitter hírcsatornák",
  extensions: "Extensions",
  imageCropper: "Kép Cropper",
  videoPlayer: "Videó lejátszó",
  dropzone: "Dropzone",
  baseConfig: "Base Config",
  audioTrackAndPlaysInline: "Hangsáv és inlay lejátszás",
  hlsLive: "HLS Live",
  forgotPassword: "Elfelejtett jelszó",
  resetPassword: "Jelszó visszaállítása",
  backToSignIn: "Vissza a bejelentkezéshez",
  enterYourEmailToSendYouAResetLink: "Adja meg e-mail címét, hogy elküldje Önnek a reset linket",
  pleaseEnterYourPasswordToReset: "Kérjük, adja meg a jelszavát visszaállításához",
  saas: "SAAS",
  overallTrafficStatus: "Átfogó forgalmi állapot",
  projectManagement: "Projektmenedzsment",
  projectTaskManagement: "Projekt feladatkezelés",
  totalSales: "Teljes értékesítés",
  simple: "Egyszerű",
  transitions: "Transitions",
  tax: "Adó",
  expenses: "Költségek",
  images: "képek",
  ratings: "Értékelések",
  containAndCover: 'Tartalmazza és fedje',
  height: 'Magasság',
  fixedRatio: 'Rögzített arány',
  gradients: 'színátmenetek',
  sizeVariants: 'Méretváltozatok',
  newIcons: 'Új ikonok',
  webApplication: 'Webalkalmazás',
  person: 'Személy',
  file: 'fájl',
  comment: 'Megjegyzés',
  form: 'Forma',
  hardware: 'Hardver',
  directional: 'Irány',
  mapAliases: 'Térkép (Aliasok)',
  dateAndTime: 'Dátum és idő',
  groups: "csoportok",
  buttonGroups: "Gomb csoportok",
  itemGroups: "Elemcsoportok",
  windows: "ablakok",
  onBoarding: "Beszállás",
  accountCreation: "Fiók létrehozása",
  treeview: "Fanézet",
  customSelectableIcons: "Egyéni választható ikonok",
  timelines: "Idővonalak",
  usage: "Használat",
  scopedSlots: "Scoped Slots",
  asyncItems: "Async tételek",
  smallDots: "Kis pontok",
  iconDots: "Ikon pontok",
  coloredDots: "Színes pontok",
  oppositeSlot: "Szemben a Slot-ot",
  denseAlert: "Sűrű figyelmeztetés",
  fixedTabs: "Fix lapok",
  rightAlignedTabs: "Jobb összehangolt lapok",
  content: "Tartalom",
  withSearch: "A Keresés funkcióval",
  iconsAndText: "Ikonok és szöveg",
  grow: "Nő",
  pagination: "Lapszámozás",
  customIcons: "Egyéni ikonok",
   courses:"Tanfolyamok",
  coursesList:"Tanfolyamok listája",
  courseDetail:"Tanfolyamok Részletesen",
  signIn:"Bejelentkezés",
  payment:"Fizetés",
  LearnWithYourConvenience:"Ismerje meg a kényelmet",
  management:"vezetés",
  design:"Tervezés",
  development:"Fejlesztés",
  top:"felső",
  new:"Új",
  trending:"Felkapott",
  bestseller:"Legjobban eladott",
  addToCart:"Kosárba",
  popularInstructors:"Népszerű oktatók",
  moreCoursesFromJamesColt:"További tanfolyamok James Colt-tól",
  whatYoWillLearn:"Mit tanulsz",
  description:"Leírás",
  aboutInstructor:"Az oktatóról",
  userSignIn:"Felhasználói bejelentkezés",
  guestCheckout:"vendég kijelentkezés",
  continueAsGuest:"Folytatás vendégként",
  paymentOptions:"Fizetési lehetőségek",
  offerCode:"Ajánló kód",
  enterCardDetails:"Adja meg a kártya adatait",
  submit:"beküldése",
  clear:"Egyértelmű",
  makePayment:"Kifizetni",
  usage: "Használat",
  withContent: "Tartalommal",
  customHeight: "Egyéni magasság"
}
