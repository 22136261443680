import _ from 'lodash'
import Vue from 'vue'

export default {
  // SQ
  getSQList: state => {
    return state.SQList
  },
  getSQ: state => {
    return state.SQ
  },
  getSQSearchTerm: state => {
    return state.SQSearchTerm
  },
  // SO
  getSOList: state => {
    return state.SOList
  },
  getSO: state => {
    return state.SO
  },
  getSOSearchTerm: state => {
    return state.SOSearchTerm
  },
  // PO
  getPOList: state => {
    return state.POList
  },
  getPO: state => {
    return state.PO
  },
  getPOSearchTerm: state => {
    return state.POSearchTerm
  },
}
