import * as T from './types'
import _ from 'lodash'


export default {
  [T.SET_DOCUMENT_LIST] (state, res) {
    let list =  _.cloneDeep(res.data)
    let type = ''
    // console.log(list)
    type = res.msg
    if (type == 'SQ') 
    {
      state.SQList = list
    } else if (type == 'SO') 
    {
      state.SOList = list
    } 
    else if (type == 'PO') 
    {
      state.POList = list
    }
    // console.log(type)
  },
  [T.SET_SO_LIST] (state, res) 
  {
    let list =  _.cloneDeep(res)

    state.SOList = list
  },
  [T.SET_PO_LIST] (state, res) 
  {
    let list =  _.cloneDeep(res)

    state.POList = list
  },
  [T.SET_SQ_LIST] (state, res) 
  {
    let list =  _.cloneDeep(res)
    state.SQList = list
  },
  [T.SET_DOCUMENT] (state, data) {
    if (data.type == 'SQ') {
      state.SQ = data
    } else if (data.type == 'SO') {
      state.SO = data
    } else {
      state.PO = data
    }
  },
  [T.SET_SQ_SEARCH_TERM] (state, data) {
    state.SQSearchTerm = data
  },
  [T.SET_SO_SEARCH_TERM] (state, data) {
    state.SOSearchTerm = data
  },
  [T.SET_PO_SEARCH_TERM] (state, data) {
    state.POSearchTerm = data
  }
}
