import * as T from './types'
import axios from 'axios'
import {api} from '@/components/apiurls'
import {authHeader} from '@/components/global'
import router from '../../../router';

export const loadDocumentList = ({commit}, payload) => {
  
  let apiurl = ''
  apiurl = api['DOCUMENT_SEARCH']

  return axios.post(apiurl, payload, authHeader()).then (res => {
    if (res.data.code == 1)
    {
      commit(T.SET_DOCUMENT_LIST, res.data)

      let search = JSON.parse(localStorage.search)
      let search_count_sq = search.sq_count
      let search_count_so = search.so_count
      let search_count_po = search.po_count
      if (payload[2] == 'type = SQ') 
      {
        search_count_sq += 1
      } 
      else if (payload[2] == 'type = SO') 
      {
        search_count_so += 1
      } 
      else 
      {
        search_count_po += 1
      }
      localStorage.search = '{"sq_count":' + search_count_sq +',"so_count":' + search_count_so + ',"po_count":' + search_count_po + '}'
    }
    else 
    {
      commit(T.SET_ERROR_SEARCH_ORG, res.data.msg)
    }
  })
  .catch(error => alert("Error login: " + error))
}
export const searchSQ = ({commit}, payload) => {
  let apiurl = ''
  apiurl = api['DOCUMENT_SEARCH']
    var sf = []
    if (payload == null) 
    {
        sf = {}

    }
    else
    {
        // sf = [
        //     "code LIKE "+ payload.code,
        //     "date = "+ payload.date,
        //     "type = " + 'SQ',
        // ]

        sf = payload
    }
  return axios.post(apiurl, sf, authHeader()).then (res => {
    if (res.data.code == 1)
    {
      commit(T.SET_SQ_LIST, res.data.data)
    }
    else 
    {
      commit(T.SET_ERROR_SEARCH_SQ, res.data.msg)
    }
  })
  .catch(error => alert("Error login: " + error))
}
export const setPOS = ({commit}, payload) => {
  commit(T.SET_PO_LIST, payload)
}
export const setSQS = ({commit}, payload) => {
  commit(T.SET_SQ_LIST, payload)
}
export const setSOS = ({commit}, payload) => {
  commit(T.SET_SO_LIST, payload)
}
export const searchPO = ({commit}, payload) => {
  let apiurl = ''
  apiurl = api['DOCUMENT_SEARCH']
    var sf = {}
    if (payload == null) 
    {
        sf = {}
    }
    else
    {
        sf = payload
    }
  return axios.post(apiurl, sf, authHeader()).then (res => {
    if (res.data.code == 1)
    {
      commit(T.SET_PO_LIST, res.data.data)
    }
    else 
    {
      commit(T.SET_ERROR_SEARCH_PO, res.data.msg)
    }
  })
  .catch(error => alert("Error login: " + error))
}
export const searchSo = ({commit}, payload) => {
  let apiurl = ''
  apiurl = api['DOCUMENT_SEARCH']
    var sf = []
    if (payload == null) 
    {
        // sf = [
        //     "code LIKE "+ "",
        //     "date = "+ "",
        //     "type = SO"
        //   ]
        sf = {}
    }
    else
    {
        // sf = [
        //     "code LIKE "+ payload.code,
        //     "date = "+ payload.date,
        //     "type = " + 'SO',
        // ]
        sf = payload
    }
  return axios.post(apiurl, sf, authHeader()).then (res => {
    if (res.data.code == 1)
    {
      commit(T.SET_SO_LIST, res.data.data)
    }
    else 
    {
      commit(T.SET_ERROR_SEARCH_SO, res.data.msg)
    }
  })
  .catch(error => alert("Error login: " + error))
}
export const loadDocument = ({commit}, payload) => {
  //retrieve organization payload = {code,code}
  
  return axios.post(api['ORG_RETRIEVE'], payload, authHeader()).then (res => {
    if (res.data.code == 1)
    {
      commit(T.SET_DOCUMENT, res.data.data)
    }
    else 
    {
      commit(T.SET_ERROR_SEARCH_ORG, res.data.msg)
    }
  })
  .catch(error => alert("Error login: " + error))
}

export const setSQSearchTerm = ({commit}, payload) => {
  commit(T.SET_SQ_SEARCH_TERM, payload)
}

export const setSOSearchTerm = ({commit}, payload) => {
  commit(T.SET_SO_SEARCH_TERM, payload)
}

export const setPOSearchTerm = ({commit}, payload) => {
  commit(T.SET_PO_SEARCH_TERM, payload)
}
// export const resetDocumentListTerm = ({commit}, payload) => {
//   commit(T.SET_DOCUMENT_SEARCH_TERM, '')
//   commit(T.SET_DOCUMENT_LIST, [])
// }