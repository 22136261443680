<template>
   <v-list-tile class="sidebar-profile">
		<v-list-tile-avatar>
			<img src="/static/avatars/user.png" alt="avatar" height="40" width="40" class="img-responsive" />
		</v-list-tile-avatar>
		<v-list-tile-content class="ml-3">
			<v-list-tile-title><p style="font-size:14px">{{this.user.user_fullname}}</p></v-list-tile-title>
		</v-list-tile-content>

		<v-menu 
			bottom
			offset-y
			left
			content-class="userblock-dropdown" 
			nudge-top="-10"
			nudge-right="0"
			transition="slide-y-transition"
			v-if="1"
		>
			<v-btn dark icon slot="activator" class="ma-0">
					<v-icon>more_vert</v-icon>
			</v-btn>

			<div class="dropdown-content" v-if="1">
				<div class="dropdown-top white--text primary">
					<span class="white--text fs-14 fw-bold d-block">{{this.user.user_fullname}}</span>
				<!--	<span class="d-block fs-12 fw-normal">info@example.com</span>-->
				</div>
				<v-list class="dropdown-list">
					<template v-for="userLink in userLinks" v-if="userLink.id !== 1">
						<v-list-tile :to="getMenuLink(userLink.path)" :key="userLink.id">
							<i :class="userLink.icon"></i>
							<span>{{$t(userLink.title)}}</span>
						</v-list-tile>
					</template>
					<template v-else>
						<v-list-tile @click="logoutUser" :key="userLink.id">
							<i :class="userLink.icon"></i>
							<span>{{$t(userLink.title)}}</span>
						</v-list-tile>
					</template>
				</v-list>
			</div>
		</v-menu>
   </v-list-tile>
</template>

<script>
import { getCurrentAppLayout } from "Helpers/helpers";
import {mapGetters, mapActions} from 'vuex'
import router from '../../router';

export default {
	data() {
		return {
			user:{},

			userLinks: [
				/*{
					id: 1,
					title: 'message.userProfile',
					icon: 'ti-user mr-3 primary--text',
					path: '/users/user-profile'
				},*/
				{
					id: 1,
					title: 'message.logOut',
					icon: 'ti-power-off mr-3 error--text'
				}
			]
		}
	},
	computed:{
		...mapGetters({
		userdetail: 'user'
		})
	},
	methods: {
		async logoutUser() 
		{
			localStorage.clear();
			this.$store.dispatch("setSOSearchTerm", '');
			this.$store.dispatch("setSOS", []);
			this.$store.dispatch("setSQSearchTerm", '');
			this.$store.dispatch("setSQS", []);
			this.$store.dispatch("setPOSearchTerm", '');
			this.$store.dispatch("setPOS", []);

			router.push("/login");
		},
		getMenuLink(path) 
		{
			return '/' + getCurrentAppLayout(this.$router) +  path;
		}
	},
	mounted()
	{
		this.user = this.$store.getters.user	//JSON.parse(this.$store.getters.user)
		
		if ( this.user.user_fullname == ''|| this.user.user_fullname == null)
		{
			this.user = JSON.parse(localStorage.user)
		}
  	}
};
</script>
