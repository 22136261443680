import * as T from './types'
import _ from 'lodash'


export default {
  [T.SET_CUSTOMER_LIST] (state, data) {
    let list =  _.cloneDeep(data)
    state.customerList = list
    // let option_list = []
    // for (let i = 0; i < list.length; i++)
    // {
    //   option_list.push({value:list[i].code, text: list[i].name, 
    //     salesperson_code: list[i].salesperson_code, terms: list[i].terms, tax_code: list[i].tax_code, currency_code: list[i].currency_code})
    // }
    
    // state.customerList = option_list
  },
  [T.SET_VESSEL_LIST] (state, data) {
    let list =  _.cloneDeep(data)
    state.vesselList = list
    // let option_list = []
    
    // for (let i = 0; i < list.length; i++)
    // {
    //   option_list.push({value:list[i].code, text: list[i].name})
    // }
    
    // state.vesselList = option_list
  },
  [T.SET_DEPT_LIST] (state, data) {
    let list =  _.cloneDeep(data)
    state.departmentList = list
    // let option_list = []
    
    // for (let i = 0; i < list.length; i++)
    // {
    //   option_list.push({value:list[i].code, text: list[i].name})
    // }
    
    // state.departmentList = option_list
  },
  [T.SET_PRODUCT_LIST] (state, data) {
    let list =  _.cloneDeep(data)
    state.productList = list
    // let option_list = []
    
    // for (let i = 0; i < list.length; i++)
    // {
    //   option_list.push({value:list[i].code, text: list[i].name})
    // }
    
    // state.productList = option_list
  },
  [T.SET_VENDOR_LIST] (state, data) {
    let list =  _.cloneDeep(data)
    state.vendorList = list
    // let option_list = []
    
    // for (let i = 0; i < list.length; i++)
    // {
    //   option_list.push({value:list[i].code, text: list[i].name})
    // }
    
    // state.vendorList = option_list
  },
}
