//Base URL
export const apiBaseUrl = 'https://v2.impa.sage9.com/api/'
// export const apiBaseUrl = '/api/'

export const api = {
    // users
    Login: apiBaseUrl + 'users/login',
    USER_RETRIEVE: apiBaseUrl + 'users/retrieve',
    USER_LIST: apiBaseUrl + 'users/list_users',
    HAS_ACCESS: apiBaseUrl + 'users/hassaccess',
    SYSTEM_VARS: apiBaseUrl + 'system_vars/retrieve',

    // sales quotation
    SQ_SAVE: apiBaseUrl + 'documents/save',
    // SQ_SEARCH: apiBaseUrl + 'documents/search',
    SQ_RETRIEVE: apiBaseUrl + 'documents/retrieve_sq',
    SQ_QUICK_IMPORT: apiBaseUrl + 'documents/quickimport',
    SQ_QUICK_SUGGEST: apiBaseUrl + 'documents/suggest',
    SQ_US_PREFER_OPTIONS: apiBaseUrl + 'documents/suggest-USpreferenceOptions',
    SQ_LSP_LQ: apiBaseUrl + 'documents/suggest_SalesPrice',
    SQ_RETRIEVE_PURCHASE_HISTORY: apiBaseUrl + 'documents/retrieve_purchase_history',

    SQ_LIST_TO_IMPORT: apiBaseUrl + 'documents/list_SQ_For_SO',
    SO_IMPORT_FOR_PO: apiBaseUrl + 'documents/list_SO_For_PO',
    LIST_PO_FROM_SO:apiBaseUrl + 'documents/list_PO_From_SO',
    SAVE_PO: apiBaseUrl + 'documents/save_po',
    UPDATE_PO: apiBaseUrl + 'documents/update_po',

    DELIVERY_RETRIEVE: apiBaseUrl + 'documents/retrieve_delivery',
    SALESMAN_RETRIEVE: apiBaseUrl + 'documents/retrieve_salesman',
    CURRENCY_CODE_PRODUCT_UNIT_COST_RETRIEVE: apiBaseUrl + 'documents/retrieve_currency_code_productUnitCost',
    CONVERT_BY_CURRENCY_RATE: apiBaseUrl + 'documents/currencies_convert',
    // CONVERTED_AVG_UNIT_COST: apiBaseUrl + 'documents/avg_unit_price',
    LIST_CURRENCY_RATE: apiBaseUrl + 'documents/list_exchange_rate',
    RETRIEVE_CURRENCY_RATE: apiBaseUrl + 'documents/retrieve_exchange_rate',
    CAL_AVG_PRICE: apiBaseUrl + 'documents/cal_avg_unit_price',
    CONVERT_TO_TARGET_CURRENCY: apiBaseUrl + 'documents/convert_to_target_currency',
    GET_CURRENCY_EXCHANGE_RATES: apiBaseUrl + 'documents/get_currency_exchange_rates',

    // sales order
    // SO_SAVE: apiBaseUrl + 'salesorder/save',
    DOCUMENT_SEARCH: apiBaseUrl + 'documents/search',
    DOCUMENT_RETRIEVE_PURCHASE_HISTORY: apiBaseUrl + 'documents/retrieve_purchase_history',
    SO_RETRIEVE: apiBaseUrl + 'documents/retrieve_so',
    SO_QUICK_IMPORT: apiBaseUrl + 'documents/quickimport',
    GENERATE_MARKING_REF: apiBaseUrl + 'documents/generate_marking_ref',
    PO_RETRIEVE: apiBaseUrl + 'documents/retrieve_po',

    // Similar Product
    SPRODUCT_LIST: apiBaseUrl + 'sproducts/list',
    SPRODUCT_RETRIEVE: apiBaseUrl + 'sproducts/retrieve',
    SPRODUCT_SAVE: apiBaseUrl + 'sproducts/save',
    SPRODUCT_DELETE: apiBaseUrl + 'sproducts/delete',

    CUST_LIST: apiBaseUrl + 'customers/list',
    CUST_LOOKUP: apiBaseUrl + 'customers/lookup',

    VESSEL_LIST: apiBaseUrl + 'vessels/list',
    VESSEL_LOOKUP: apiBaseUrl + 'vessels/lookup',
    
    VENDOR_LIST: apiBaseUrl + 'vendors/list',
    VENDOR_LOOKUP: apiBaseUrl + 'vendors/lookup',

    PRODUCT_LIST: apiBaseUrl + 'products/list',
    PRODUCT_LOOKUP: apiBaseUrl + 'products/lookup',

    DEPARTMENT_LIST: apiBaseUrl + 'departments/list',
    DEPARTMENT_LOOKUP: apiBaseUrl + 'departments/lookup',

    SPRODUCT_LIST: apiBaseUrl + 'sproducts/list',
    SPRODUCT_LOOKUP: apiBaseUrl + 'sproducts/lookup',
    
    CUSTOMER_ADDRESS_RETRIEVE: apiBaseUrl + 'customers/retrieve_u8_address',
    VENDOR_ADDRESS_RETRIEVE:apiBaseUrl + 'vendors/retrieve_u8_address',

    VOID_PO: apiBaseUrl + 'documents/void_po'
}

export const authHeader = function ()
{	
    const accessToken = localStorage.access_token
    const headers = {'headers':{
        'Accept': 'application/json',
        'Authorization': 'Bearer '+ accessToken
    }}
          
    return headers
}  
