// Sidebar Routers
export const menus = {
	'message.general': [
		{
			action: 'zmdi-view-dashboard',
			title: 'message.dashboard',
			active: true,
			path: '/dashboard',
			label: 'Old',
			visible: 1
		},
		{
			action: 'zmdi-book',
			title: 'Sales Quotations',
			path: '/sales-quotations',
			label: 'New',
			visible: 1
		},
		{
			action: 'zmdi-book',
			title: 'Sales Orders',
			path: '/sales-orders',
			label: 'New',
			visible: 1
		},
		{
			action: 'zmdi-book',
			title: 'Purchase Orders',
			path: '/purchase-orders',
			label: 'New',
			visible: 1
		},
	]
}
