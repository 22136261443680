import Full from 'Container/Full'

// const Dashboard = () => import('Views/app/dashboard');
// const Devices = () => import('Views/app/devices');
// const Medias = () => import('Views/app/medias');
// const Purchase = () => import('Views/app/purchase');
// const Schedule = () => import('Views/app/schedule');
// Views
const Dashboard = () => import('@/views/Dashboard')
// SQ
const SalesQuotations = () => import('@/views/SQ/master')
// const SalesQuotation = () => import('@/views/SQ/details v1')
const SalesQuotation = () => import('@/views/SQ/details')

// // SO
const SalesOrders = () => import('@/views/SO/master')
const SalesOrder = () => import('@/views/SO/details')
// // const SalesOrder = () => import('@/views/SO/details v2')
// // PO
const PurchaseOrders = () => import('@/views/PO/master')
const PurchaseOrder = () => import('@/views/PO/details')

export default {
  path: '/',
  component: Full,
  redirect: '/dashboard',
  children: [
    {
      name:'Dashboard',
      path: '/dashboard',
      component: Dashboard,
      meta: {
        requiresAuth: true,
        title: 'message.dashboard',
        breadcrumb: null
      }
    },
    {
      path: '/sales-quotations',
      component: SalesQuotations,
      name:'Sales Quotations',
      meta: {
        requiresAuth: true,
        title: 'Sales Quotations',
        breadcrumb: null
      }
    },
    {
      path: 'sales-quotation/:id',
      component: SalesQuotation,
      name: 'Sales Quotation',
      meta: {
        requiresAuth: true,
        title: 'Sales Quotation',
        breadcrumb: null
      }
    },
    {
      path: '/sales-orders',
      component: SalesOrders,
      name:'Sales Orders',
      meta: {
        requiresAuth: true,
        title: 'Sales Orders',
        breadcrumb: null
      }
    },
    {
      path: '/sales-order/:id',
      component: SalesOrder,
      name:'Sales Order',
      meta: {
        requiresAuth: true,
        title: 'Sales Order',
        breadcrumb: null
      }
    },
    {
      path: '/purchase-orders',
      component: PurchaseOrders,
      name:'Purchase Orders',
      meta: {
        requiresAuth: true,
        title: 'Purchase Orders',
        breadcrumb: null
      }
    },
    {
      path: '/purchase-order/:id',
      component: PurchaseOrder,
      name:'Purchase Order',
      meta: {
        requiresAuth: true,
        title: 'Purchase Order',
        breadcrumb: null
      }
    },
  ]
}
