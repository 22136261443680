<!-- Side Structure -->
<template>
  	<div class="sidebar" :class="sidebarSelectedFilter.class">
    	<vue-perfect-scrollbar class="scroll-area" :settings="settings">
        <v-toolbar flat class="transparent scroll-area navigation">
          <v-list>
				<!-- <app-logo class="ml-3 mt-4 mb-4"></app-logo> font-family:Arial Black-->
				<p style="font-size:36px;text-align:center;" class="mt-4">iQuote</p>
				<user-block class="mt-4"></user-block>

				<template v-for="(category, key) in menus">
					<div :key="key">
						<template v-for="item in category">
							<template v-if="item.visible">
								<v-list-group class="not-submenu" :key="item.title" prepend-icon="arrow_right" append-icon="" no-action v-model="item.active">
									<v-list-tile slot="activator">
										<v-list-tile-content >
											<v-list-tile-title>
												<router-link :to="item.path">
												<!-- <router-link :to="!item.exact ? `/${getCurrentAppLayoutHandler() + item.path}` : item.path"> -->
													<i class="mr-3 zmdi" :class="item.action"></i>
													<span>{{ textTruncate($t(item.title)) }}</span>
												</router-link>
											</v-list-tile-title>
										</v-list-tile-content>
									</v-list-tile>
								</v-list-group>
							</template>	
						</template>
					</div>
				</template>
          </v-list>
        </v-toolbar>
    	</vue-perfect-scrollbar>
  	</div>
</template>

<script>
import UserBlock from "./UserBlock";
import { textTruncate, getCurrentAppLayout } from "Helpers/helpers";
import { mapGetters } from "vuex";
import AppLogo from "Components/AppLogo/AppLogo";

export default {
  data() {
    return {
		user: {},
		menu: {},
		settings: {
			maxScrollbarLength: 160
      	}
    };
  },
  components: {
    UserBlock,
    AppLogo
  },
  computed: {
    ...mapGetters(
			["sidebarSelectedFilter", "menus"],
			{ userdetail: 'user' },
			{ menus: 'menus' }
		)
	},
	async mounted() 
	{
		this.user = await this.$store.getters.user
		this.menu = await this.$store.getters.menus

		if ( this.user.user_fullname == ''|| this.user.user_fullname == null)
		{
			this.user = JSON.parse(localStorage.user)
		}

		// if ( this.user.type == 'SA') {
		// 	this.menus['message.general'][1].visible = 1
		// 	this.menus['message.general'][2].visible = 1
		// 	this.menus['message.general'][3].visible = 0
		// 	this.menus['message.general'][4].visible = 1
		// } else if (this.user.type == 'A') {
		// 	this.menus['message.general'][1].visible = 1
		// 	this.menus['message.general'][2].visible = 1
		// 	this.menus['message.general'][3].visible = 0
		// 	this.menus['message.general'][4].visible = 1
		// } else {
		// 	this.menus['message.general'][1].visible = 0
		// 	this.menus['message.general'][2].visible = 0
		// 	this.menus['message.general'][3].visible = 1
		// 	this.menus['message.general'][4].visible = 1
		// }
		//this.$forceUpdate();
	},
  methods: {
    textTruncate(text) {
      return textTruncate(text, 18);
    },
    getCurrentAppLayoutHandler() {
      return getCurrentAppLayout(this.$router);
    }
  }
};
</script>
