import * as actions from './actions'
import getters from './getters'
import mutations from './mutations'

const state = {
  customerList:[],
  vesselList:[],
  vendorList:[],
  productList:[],
  departmentList:[],
}

export default {
  state,
  actions,
  mutations,
  getters
}
