export const SET_DOCUMENT_LIST = 'SET_DOCUMENT_LIST'
export const SET_DOCUMENT = 'SET_DOCUMENT'
export const SET_SQ_SEARCH_TERM = 'SET_SQ_SEARCH_TERM'
export const SET_SO_SEARCH_TERM = 'SET_SO_SEARCH_TERM'
export const SET_PO_SEARCH_TERM = 'SET_PO_SEARCH_TERM'
export const SET_ERROR_SEARCH_SO = 'SET_ERROR_SEARCH_SO'
export const SET_ERROR_SEARCH_SQ = 'SET_ERROR_SEARCH_SQ'
export const SET_ERROR_SEARCH_PO = 'SET_ERROR_SEARCH_PO'
export const SET_SO_LIST = 'SET_SO_LIST'
export const SET_SQ_LIST = 'SET_SQ_LIST'
export const SET_PO_LIST = 'SET_PO_LIST'
